import { useStore } from 'vuex'
import { computed, reactive, ref } from 'vue'

export default function usePolitic(initStore = null) {
  const store = initStore || useStore()
  const roles = reactive(['customer', 'affiliate', 'manager', 'partner', 'admin'])

  const isAdmin = computed(() => store.state.user.user?.role === 'admin')
  const isCustomer = computed(() => store.state.user.user?.role === 'customer')
  const isPartner = computed(() => store.state.user.user?.role === 'partner')
  const isAffiliate = computed(() => store.state.user.user?.role === 'affiliate')
  const isAllRole = ref(true)
  return {
    isAdmin,
    isCustomer,
    isAllRole,
    isPartner,
    roles,
    isAffiliate,
  }
}
