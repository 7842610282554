export function useFormValidator() {
  const requireRule = {
    fn: (val) => (typeof val === 'number' ? true : !!val),
    required: true,
    message: 'Данное поле обязательное',
  }
  const emailRule = {
    fn: (val) => {
      const regex =
        // eslint-disable-next-line no-control-regex
        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
      return regex.test(val)
    },
    message: 'Некорректный email',
  }
  const numberRule = {
    fn: (val) => {
      if (val === '') return true
      return /^-?[0-9]*[.]?[0-9]+$/.test(val)
    },
    pattern: /^-?[0-9.]*$/,
    message: 'Поле может содержать только цифры',
  }
  const onlyRuText = {
    fn: (val) => /^[-\sА-Яа-яËё]*$/.test(val),
    pattern: /^[-\sА-Яа-яËё]*$/,
    message: 'Поле может содержать только русские буквы и тире',
  }
  const onlyText = {
    fn: (val) => /^[-,.№"'\sA-Za-zА-Яа-яËё0-9]*$/.test(val),
    pattern: /^[-,.№"'\sA-Za-zА-Яа-яËё0-9]*$/,
    message: 'Поле может содержать только буквы, цифры и символы -,.№',
  }
  const minSymbolRule = (min) => ({
    fn: (val) => val.length >= min,
    min,
    message: `Поле должно содержать минимум ${min} символов`,
  })
  const rangeSymbolRule = (min, max) => ({
    fn: (val) => val.length >= min && val.length <= max,
    min,
    message: `Поле должно содержать не менее ${min} символов и не более ${max}`,
  })
  const lenRule = (len) => ({
    fn: (val) => val.length === len,
    len,
    message: `Поле может содержать только ${len} символов`,
  })
  const onlyEnText = {
    fn: (val) => /^[A-Za-z]*$/.test(val),
    message: 'Поле может содержать только английские символы',
  }

  // сравнивает с годом декларации
  const checkYearValue = (year, message = 'Год не соответсвует отчетному периоду') => ({
    fn: (val) => {
      const d = new Date(val?.split('.')?.reverse()?.join('/'))
      return d.getFullYear() === year
    },
    message,
  })
  const checkTaxPaidYearValue = (year, message = 'Год не соответсвует отчетному периоду') => ({
    fn: (val) => {
      if (val === null || val === '' || val === 'null') return true
      const d = new Date(val?.split('.')?.reverse()?.join('/'))
      return d.getFullYear() >= year
    },
    message,
  })
  const checkExpenseDate = (year, message = 'Год не соответсвует отчетному периоду') => ({
    fn: (val) => {
      if (val === null || val === '') return true
      const d = new Date(val?.split('.')?.reverse()?.join('/'))
      return d.getFullYear() <= year
    },
    message,
  })
  // сравнивает с календарным годом
  const checkYear = (year, message = 'Год не соответсвует отчетному периоду') => ({
    fn: (val) => {
      const d = new Date(val?.split('.')?.reverse()?.join('/'))
      return d.getFullYear() <= year
    },
    message,
  })

  return {
    requireRule,
    emailRule,
    numberRule,
    onlyRuText,
    onlyText,
    minSymbolRule,
    lenRule,
    onlyEnText,
    checkYearValue,
    checkTaxPaidYearValue,
    checkExpenseDate,
    checkYear,
    rangeSymbolRule
  }
}
