export default {
  /**
   * @param state
   * @param user {User}
   */
  update_user: (state, user) => {
    user.access_exp = new Date(user.access_exp)
    user.refresh_exp = new Date(user.refresh_exp)
    state.user = user
    localStorage.user = JSON.stringify(user)
  },
  updateUserField: (state, config) => {
    const keys = Object.keys(config)
    if (keys.length === 0) return
    keys.forEach((key) => {
      state.user[key] = config[key]
    })
    localStorage.user = JSON.stringify(state.user)
  },
  signOut: (state) => {
    state.user = undefined
    localStorage.removeItem('user')
  },
  updateShowUser: (state, data) => (state.showUser = data),
  updateShowUserProfiles: (state, data) => (state.showUserProfiles = data),
  updateShowUserInvoices: (state, data) => (state.showUserInvoices = data),
  clearShowUserInfo: (state) => {
    state.showUser = null
    state.showUserInvoices = null
    state.showUserProfiles = null
  },
  // Profile
  setActiveProfile: (state, value) => {
    state.profile = value
  },
  setProfileList: (state, value) => {
    state.profileList = value
  },
  setErrorState: (state, value) => {
    state.stateError = value
  },
  clearErrorState: (state) => {
    state.stateError = null
  },
  clearState: (state) => {
    state.profile = null
    state.profileList = []
  },
  updateExanteRegToken: (state, token) => {
    state.exanteRegToken = token
  },
  updateExanteError: (state, value) => {
    state.exanteError = value
  },
  // end Profile
}
