import axios from '@/plugins/axios'

export default function CheckAdminRoleMiddleware({ next, store }) {
  const localStoreUser = JSON.parse(localStorage.getItem('user'))
  if (localStoreUser?.role === 'admin') return next()
  axios({
    url: '/users/role',
    method: 'GET',
  })
    .then((res) => {
      if (['admin'].includes(res.data?.role)) {
        store.commit('user/updateUserField', {
          role: res.data.role,
        })
        next()
      } else next({ name: 'account home' })
    })
    .catch(() => {
      next({ name: 'account home' })
    })
}
