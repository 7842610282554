<template>
  <transition name="slide-fade">
    <div class="ng_right_sidebar" v-if="visible">
      <div class="ng_right_sidebar--main">
        <div class="bg-gray padding--20">
          <img
            class="button--text-hover"
            width="20"
            @click="closeSidebar"
            src="@/assets/close_icon.svg"
            alt="close_icon"
          />
        </div>
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script>
import { ref } from 'vue'

export default {
  emits: ['close-sidebar-event'],
  setup(_, { emit }) {
    const visible = ref(false)
    const closeSidebar = () => {
      visible.value = false
      emit('close-sidebar-event')
    }
    const showSidebar = () => {
      visible.value = true
    }

    return {
      visible,
      closeSidebar,
      showSidebar,
    }
  },
}
</script>

<style lang="sass" scoped>
.ng_right_sidebar
  position: fixed
  left: 0
  right: 0
  top: 0
  bottom: 0
  background: rgba(0, 0, 0, 0.33)
  display: flex
  justify-content: flex-end
  z-index: 1000
  &--main
    position: relative
    width: 30%
    height: 100%
    background: #fff
    overflow: auto
    padding-bottom: 40px
.ng_right_sidebar--main
  transition: all 0.5s ease-out
.slide-fade-enter-active
  transition: all 0.5s ease-out

.slide-fade-leave-active
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1)

.slide-fade-enter-from .ng_right_sidebar--main,
.slide-fade-leave-to .ng_right_sidebar--main
  transform: translateX(50%)
  opacity: 0
@media(max-width: 767px)
  .ng_right_sidebar
    &--main
      position: relative
      width: 100%
      height: 100%
      background: #fff
      overflow: auto
      padding-bottom: 40px
</style>
