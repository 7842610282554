import CheckAdminRoleMiddleware from '@/router/middlewares/CheckAdminRoleMiddleware'
import GetUserInfoMiddleware from '@/router/Admin/GetUserInfoMiddleware'
import Promocode from '@/router/Admin/Promocode'
import BrokerInfo from '@/router/Admin/BrokerInfo'
import OksmInfo from '@/router/Admin/OksmInfo'
import manager from '@/router/Account/manager+'
import taxPlan from '@/router/Account/taxPlan'
const UserShow = () => import('@/views/admin/UserShow')

export default [
  ...BrokerInfo,
  ...Promocode,
  ...OksmInfo,
  ...manager,
  ...taxPlan,
  {
    path: 'invoices/:id',
    name: 'admin invoice show',
    props: true,
    component: null,
    meta: {
      middleware: [CheckAdminRoleMiddleware],
    },
  },
  {
    path: 'users/:userId',
    name: 'user show',
    props: true,
    component: UserShow,
    meta: {
      middleware: [GetUserInfoMiddleware],
    },
  },
]
