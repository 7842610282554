import getters from './getters'
import actions from './actions'
import mutations from './mutations'
const state = {
  invoiceActivePage: 'invoice_show',
  declarationActiveStep: 'Missing',
  globalEmitter: undefined,
  cfrActiveStep: 'Add_account',
  redirectLoader: false,
  toUrl: undefined,
}
export default {
  state,
  getters,
  actions,
  mutations,
}
