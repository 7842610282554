<template>
  <div>
    <el-checkbox v-model="checked">
      <span>{{ label }}</span>
      <slot></slot>
    </el-checkbox>
  </div>
</template>

<script>
import { onUpdated, ref, watch } from 'vue'

export default {
  name: 'NgGlobalCheckBox',
  props: {
    label: String,
    value: Boolean,
    valid: Boolean,
  },
  emits: ['update:value'],
  setup(props, { emit }) {
    const checked = ref(props.value)
    watch(checked, (value) => {
      emit('update:value', value)
    })
    onUpdated(() => {
      if (props.value === false) checked.value = false
      if (props.value === true) checked.value = true
    })
    return {
      checked,
    }
  },
}
</script>

<style scoped lang="sass">
.red-checkbox
  color: red
</style>
