import { axiosWithoutToken } from '../../../../plugins/axios'

export default (store, config) => {
  axiosWithoutToken({
    url: '/users',
    method: 'POST',
    data: config.body,
  })
    .then((res) => {
      /**
       * @type {User}
       */
      const user = res.data
      store.commit('update_user', user)
      config.success_callback?.(user)
    })
    .catch((err) => config.error_callback?.(err))
}
