export default {
  setActiveProfile: (state, value) => {
    state.profile = value
  },
  setProfileList: (state, value) => {
    state.profileList = value
  },
  setErrorState: (state, value) => {
    state.stateError = value
  },
  clearErrorState: (state) => {
    state.stateError = null
  },
  clearState: (state) => {
    state.profile = null
    state.profileList = []
  },
}
