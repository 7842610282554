import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

/**
 * Функция для импорта глобального импорта компонентов в проект
 * Все компоненты, которые начинаются с NgGlobal
 * будут добавлены в глобальные компоненты проекта
 * ( не надо будет использовать локальное подключение через components )
 * @param app - экземпляр Vue приложения
 * @constructor
 */
export default function GlobalComponentRegistration(app) {
  const requireComponent = require.context(
    // Относительный путь до каталога компонентов
    './components',
    // Обрабатывать или нет подкаталоги
    false,
    // Регулярное выражение для определения файлов базовых компонентов
    /NgGlobal[A-Z]\w+\.(vue|js)$/
  )

  requireComponent.keys().forEach((fileName) => {
    // Получение конфигурации компонента
    const componentConfig = requireComponent(fileName)

    // Получение имени компонента в PascalCase
    const componentName = upperFirst(
      camelCase(
        // Получаем имя файла независимо от глубины вложенности
        fileName
          .split('/')
          .pop()
          .replace(/\.\w+$/, '')
      )
    )

    // Глобальная регистрация компонента
    app.component(
      componentName,
      // Поиск опций компонента в `.default`, который будет существовать,
      // если компонент экспортирован с помощью `export default`,
      // иначе будет использован корневой уровень модуля.
      componentConfig.default || componentConfig
    )
  })
  // app.component('ng-google-authenticate-button', NgGoogleAuthenticateButton)
}
