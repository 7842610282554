import invoices from './Account/invoices'
import CheckExanteTokenInAccount from './middlewares/CheckExanteTokenInAccount'
import UserIsNotLoggedInMiddleware from './middlewares/UserIsNotLoggedInMiddleware'
const AccountHome = () => import('../views/account/AccountHome')
const CustomerShow = () => import('@/views/account/CustomerShow')

export default [
  ...invoices,
  {
    name: 'account home',
    path: '',
    component: AccountHome,
    meta: {
      middleware: [CheckExanteTokenInAccount, UserIsNotLoggedInMiddleware],
    },
  },
  {
    name: 'customer show',
    path: 'user_show/:id',
    component: CustomerShow,
    props: true,
    meta: {
      middleware: [CheckExanteTokenInAccount, UserIsNotLoggedInMiddleware],
    },
  },
]
