import ReloadInvoiceMiddleware from '../middlewares/ReloadInvoiceMiddleware'
import invoicesRoutes from '@/router/Account/invoicesRoutes'
import UserIsNotLoggedInMiddleware from '../middlewares/UserIsNotLoggedInMiddleware'
const InvoiceView = () => import('@/views/account/invoice/InvoiceView')
const TaxPlanCustomerView = () => import('@/views/account/invoice/TaxPlanCustomerView')
const TMS = () => import('@/components/Invoice/Declaration/TMS')
const AddItems = () => import('@/components/Invoice/Declaration/AddItems')
const PersonalData = () => import('@/components/Invoice/Declaration/PersonalData')
const IncomesView = () => import('@/views/admin/IncomesView')

export default [
  {
    path: 'invoices/:id',
    name: 'invoice show',
    props: true,
    component: InvoiceView,
    children: [
      ...invoicesRoutes,
      {
        name: 'tms',
        path: 'declarations/:good_id/tms',
        component: TMS,
        props: true,
        meta: {
          middleware: [ReloadInvoiceMiddleware, UserIsNotLoggedInMiddleware],
        },
      },
      {
        name: 'add_incomes',
        path: 'declarations/:good_id/add_incomes',
        component: AddItems,
        props: true,
        meta: {
          middleware: [ReloadInvoiceMiddleware, UserIsNotLoggedInMiddleware],
        },
      },
      {
        name: 'deductions',
        path: 'declarations/:good_id/deductions',
        component: AddItems,
        props: true,
        meta: {
          middleware: [ReloadInvoiceMiddleware, UserIsNotLoggedInMiddleware],
        },
      },
      {
        name: 'personal_data',
        path: 'declarations/:good_id/personal_data',
        component: PersonalData,
        props: true,
        meta: {
          middleware: [ReloadInvoiceMiddleware, UserIsNotLoggedInMiddleware],
        },
      },
      {
        name: 'taxplan',
        path: 'taxplan/:good_id',
        component: TaxPlanCustomerView,
        props: true,
        meta: {
          middleware: [ReloadInvoiceMiddleware, UserIsNotLoggedInMiddleware],
        },
      },
      {
        name: 'calculated_incomes',
        path: 'calculated_incomes',
        component: IncomesView,
        props: true,
        meta: {
          middleware: [ReloadInvoiceMiddleware, UserIsNotLoggedInMiddleware],
        },
      },
    ],
    meta: {
      middleware: [ReloadInvoiceMiddleware, UserIsNotLoggedInMiddleware],
    },
  },
]
