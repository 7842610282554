export default {
  updateInvoiceList: (state, data) => (state.invoiceList = data),
  updateCurrentInvoice: (state, data) => {
    state.currentInvoice = data
    state.currentInvoiceVersion = Date.now()
  },
  updateAsyncInvoiceTimer: (state, data) => (state.asyncInvoiceTimer = data),
  updateAsyncDeclarationTimer: (state, data) => (state.asyncDeclarationTimer = data),
  clearAsyncInvoiceTimer: (state) => {
    clearTimeout(state.asyncInvoiceTimer)
    state.asyncInvoiceTimer = undefined
  },
  clearAsyncDeclarationTimer: (state) => {
    clearTimeout(state.asyncDeclarationTimer)
    state.asyncDeclarationTimer = undefined
  },
  updateCurrentDeclaration: (state, data) => (state.currentDeclaration = data),
  updateCurrentMissings: (state, data) => (state.currentMissings = data),
  updateCurrentTransfers: (state, data) => (state.currentTransfers = data),
  updateCurrentUnclosedShorts: (state, data) => (state.currentUnclosedShorts = data),
  updateCurrentAdditionalIncomes: (state, data) => (state.currentAdditionalIncomes = data),
  updateCurrentCfr: (state, data) => (state.currentCfr = data),
  updatePromocode: (state, data) => (state.activePromocode = data),
  updatePromocodeList: (state, data) => (state.promocodeList = data),
  clearAllCurrentDeclaration: (state) => {
    state.currentDeclaration = null
    state.currentMissings = []
    state.currentTransfers = []
    state.currentUnclosedShorts = []
    state.currentAdditionalIncomes = []
    state.activePromocode = null
    state.promocodeList = []
  },
}
