<script>
import BitrixOptionController from '@/controllers/BitrixOptionsController'
import newLandingController from '@/controllers/LandingController'
import { useStore } from 'vuex'
import { computed, watch } from 'vue'
import { useRouter } from 'vue-router'
import usePolitic from '@/use/usePolitic'
import useScroll from '@/use/useScroll'

export default {
  setup() {
    const chatOptions = BitrixOptionController()
    chatOptions.methods.hideContactForm()
    const store = useStore()
    const user = computed(() => store.state.user.user)
    const router = useRouter()
    const { scrollTop } = useScroll()
    watch(router.currentRoute, scrollTop)
    store.commit('service/createGlobalEmitter')
    const getSettingMenuStatus = computed(() => store.getters['menuState/getSettingsState'])
    const setSettingsClose = () => {
      if (getSettingMenuStatus.value) store.commit('menuState/setSettingsClose')
    }
    const politic = usePolitic()
    newLandingController().methods.putUtmToLocalStorage()

    const init = () => {
      store.dispatch('user/Action_LoadUserInfoFromLocalStore')
      if (store.getters['user/is_login']) {
        if (politic?.isAdmin.value) {
          store.dispatch('user/getUserInfo')
          store.dispatch('user/getProfiles')
        } else {
          store.dispatch('user/getProfiles')
        }
      }
    }

    init()

    const utm = localStorage.getItem('ng_utm')
    return {
      BitrixOptionController,
      setSettingsClose,
      utm,
      user,
      ...politic,
    }
  },
}
</script>

<template>
  <div class="wrapper">
    <div class="no-select box-main" @click="setSettingsClose">
      <div class="container">
        <router-view />
      </div>
    </div>
  </div>
</template>

<style lang="scss"></style>
