import { reactive, ref, watch } from 'vue'
import EventEmitter from 'events'

/**
 * @typedef Field
 * @type {Object}
 * @property valid {Ref<UnwrapRef<boolean>>} - является ли поле валидным
 * @property value {ToRef<any>} - значение поля
 * @property emitter {Emitter} - абъект событийной модели поля
 * @property errors {UnwrapNestedRefs<*[]>} - массив ошибок по валидации
 * @property validate {(val: any) => void} - вызов валидации вручную
 * @property touched {Ref<UnwrapRef<boolean>>} - активна ли валидация
 * @property blur {() => void} - активирет начало проверки данных
 * @event emitter#validated - окончание валидации поля
 */

/**
 *
 * @param field
 * @returns {Field}
 */
export function useFormField(field) {
  const value = ref(field.value ?? field?.type?.() ?? '')
  const valid = ref(true)
  const type = field?.type || String
  const errors = reactive([])
  class Emitter extends EventEmitter {}
  const emitter = new Emitter()
  const touched = ref(false)

  const reassign = (val) => {
    errors.splice(0, errors.length)
    field?.transforms?.forEach((tFn) => {
      value.value = tFn(value.value)
    })
    valid.value = true
    field?.validators?.forEach((validator) => {
      if (!validator.fn(val)) {
        valid.value = false
        errors.push(validator.message)
      }
    })
    emitter.emit('validated', val)
  }
  const validate = () => {
    reassign(value.value)
  }

  watch(value, reassign)
  reassign(value.value)

  const toJson = () => type(value.value)

  return {
    value,
    valid,
    errors,
    emitter,
    validate,
    touched,
    blur: () => {
      touched.value = true
    },
    toJson,
  }
}
