import { useForm } from '../useForm'
import useAuthMethods from './useAuthMethod'
import { useFormValidator } from '../useFormValidator'
import useFormTransform from '@/use/useFormTransform'

/**
 *
 * @returns {{form: Form, auth: function}}
 */
export default function useRegistrationMethod() {
  const { requireRule, emailRule, minSymbolRule } = useFormValidator()
  const { lowerCase } = useFormTransform()

  const form = useForm({
    email: {
      value: '',
      validators: [requireRule, emailRule],
      transforms: [lowerCase],
    },
    password: {
      value: '',
      validators: [requireRule, minSymbolRule(8)],
    },
    confirm_password: {
      value: '',
      validators: [requireRule, minSymbolRule(8)],
      repeat: 'password',
    },
    phone: {
      value: '',
      validators: [],
    },
    confirm_agreement: {
      value: false,
      validators: [requireRule],
    },
  })

  return {
    form,
    ...useAuthMethods({
      form,
      method: 'user/actionRegistration',
    }),
  }
}
