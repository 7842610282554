/**
 * @param store
 * @param config {request_config}
 */
import axios from '../../../../plugins/axios'

export default function (store, config) {
  axios({
    url: '/orders',
    method: 'GET',
  })
    .then((res) => console.log(res))
    .catch((err) => console.error(err))
}
