import { createRouter, createWebHistory } from 'vue-router'
import AccountRouter from '@/router/AccountRouter'
import store from '../store'
import middlewarePipeline from './middlewares'
import UserIsNotLoggedInMiddleware from './middlewares/UserIsNotLoggedInMiddleware'
import indexMiddleware from '@/router/middlewares/indexMiddleware'
import globalMiddleware from './GlobalMiddleware'
import checkPasswordTokenMiddleware from './checkPasswordTokenMiddleware'
import UserIsAuthorizedMiddleware from './middlewares/UserIsAuthorizedMiddleware'
import AdminRouter from '@/router/AdminRouter'
import CheckAdminRoleMiddleware from '@/router/middlewares/CheckAdminRoleMiddleware'
import Home from '@/views/Home'
import useRollbar from '@/plugins/rollbar'
import SaveExanteTokenMiddleware from './middlewares/SaveExanteTokenMiddleware'
import { RedirectFromExanteRegistrationMiddleware } from './middlewares/RedirectFromExanteRegistrationMiddleware'
const Authorization = () => import('../views/auth/Authorization')
const Registration = () => import('../views/auth/Registration')
const ChangePassword = () => import('../views/auth/ChangePassword')
const ExanteRegistration = () => import('@/views/auth/ExanteRegistration')
const AccountView = () => import('../views/account/AccountView')
const ChangePasswordLogout = () => import('../views/auth/ChangePasswordLogout')
const AdminView = () => import('@/views/admin/AdminView')
const NoPageFound = () => import('../views/NoPageFound')
const Page500 = () => import('@/views/Page500')

const routes = [
  {
    name: 'Home',
    path: '/',
    component: Home,
    meta: {
      middleware: [indexMiddleware],
    },
  },
  {
    path: '/account',
    component: AccountView,
    children: [...AccountRouter],
    meta: {
      middleware: [UserIsNotLoggedInMiddleware],
    },
  },
  {
    path: '/admin',
    component: AdminView,
    children: [...AdminRouter],
    meta: {
      middleware: [UserIsNotLoggedInMiddleware, CheckAdminRoleMiddleware],
    },
  },
  {
    name: 'authorization',
    path: '/authorization',
    component: Authorization,
    meta: {
      middleware: [UserIsAuthorizedMiddleware],
    },
  },
  {
    name: 'registration',
    path: '/registration',
    component: Registration,
    meta: {
      middleware: [UserIsAuthorizedMiddleware],
    },
  },
  {
    name: 'exanteRegistration',
    path: '/registration_exante',
    component: ExanteRegistration,
    meta: {
      middleware: [UserIsAuthorizedMiddleware, SaveExanteTokenMiddleware, RedirectFromExanteRegistrationMiddleware],
    },
  },
  {
    name: 'change_password',
    path: '/change_password/:token',
    component: ChangePassword,
    props: true,
    meta: {
      middleware: [checkPasswordTokenMiddleware],
    },
  },
  {
    name: 'change_password_logout',
    path: '/change_password_logout',
    component: ChangePasswordLogout,
  },
  {
    path: '/404',
    name: '404',
    component: NoPageFound,
  },
  // {
  //   path: '/:pathMatch(.*)*',
  //   redirect: '/404'
  // },
  {
    path: '/500',
    name: '500',
    component: Page500,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  base: '/app',
})

router.beforeEach((to, from, next) => {
  const context = {
    to,
    from,
    next,
    store,
    notification: {
      ...useRollbar(),
    },
  }
  return globalMiddleware[0]({
    ...context,
    next: middlewarePipeline(context, globalMiddleware, 1),
  })
})
router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next()
  }
  const { middleware } = to.meta

  const context = {
    to,
    from,
    next,
    store,
  }

  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  })
})

export default router
