import { createApp } from 'vue'
import App from './App.vue'
import VueGtag from 'vue-gtag-next'
import router from './router'
import store from './store'
import GAuth from './plugins/GoogleAuth'
import JsonViewer from "vue3-json-viewer"
import GlobalComponentRegistration from './GlobalComponentRegistration'
import {
  ElButton, ElCheckbox, ElInput, ElRadio, ElSelect, ElSwitch, ElCard, ElCollapse,
  ElEmpty, ElPagination, ElTable, ElTag, ElTree, ElDropdown, ElMenu, ElTabs, ElDialog, ElMessage,
  ElMessageBox, ElNotification, ElPopover, ElTooltip, ElCol, ElRow, ElIcon, ElDatePicker, ElDivider,
  ElAutocomplete, ElForm, ElBreadcrumb, ElInputNumber, ElScrollbar, ElAside, ElContainer, ElMain } from 'element-plus'
import NotificationsPlugin from './plugins/NotificationsPlugin'
import './style/reset.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import './style/element/index.scss'
import './style/style.sass'
import MetaInfo from 'vue-meta-info'
import VueTheMask from 'vue-the-mask'
import "vue3-json-viewer/dist/index.css"

const app = createApp(App)
  .use(store)
  .use(VueGtag, {
    property: { id: 'G-VB3GNSWQ03' },
  })
  .use(router)
  .use(ElButton)
  .use(ElCheckbox)
  .use(ElInput)
  .use(ElRadio)
  .use(ElSelect)
  .use(ElSwitch)
  .use(ElCard)
  .use(ElCollapse)
  .use(ElEmpty)
  .use(ElPagination)
  .use(ElTable)
  .use(ElTag)
  .use(ElTree)
  .use(ElDropdown)
  .use(ElMenu)
  .use(ElTabs)
  .use(ElDialog)
  .use(ElMessage)
  .use(ElMessageBox)
  .use(ElNotification)
  .use(ElPopover)
  .use(ElTooltip)
  .use(ElCol)
  .use(ElRow)
  .use(ElIcon)
  .use(ElDatePicker)
  .use(ElDivider)
  .use(ElAutocomplete)
  .use(ElForm)
  .use(ElBreadcrumb)
  .use(ElInputNumber)
  .use(ElScrollbar)
  .use(ElAside)
  .use(ElMain)
  .use(ElContainer)
  .use(NotificationsPlugin)
  .use(MetaInfo)
  .use(VueTheMask)
  .use(JsonViewer)
  .use(GAuth, {
    clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
    scope: 'email',
    prompt: 'select_account',
    fetch_basic_profile: false,
  })
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

GlobalComponentRegistration(app)

app.mount('#app')
