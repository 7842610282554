export default function useFormTransform() {
  const dotNumber = (value) => {
    const localValue = `${value}`
    const reg = /[,]/
    return localValue.replace(reg, '.')
  }
  const lowerCase = (value) => {
    return value.toLowerCase()
  }
  const subStringNumber = (val) => val.replace(/^0*(?=0\.|[1-9])/, '')
  return {
    dotNumber,
    lowerCase,
    subStringNumber,
  }
}
