import { createStore, createLogger } from 'vuex'
import modules from './modules'

const store = createStore({
  state: {
    version: '1.0.0',
  },
  modules,
  strict: process.env.NODE_ENV !== 'production',
  plugins: process.env.NODE_ENV !== 'production' ? [createLogger()] : [],
})

// eslint-disable-next-line no-restricted-syntax
for (const moduleName of Object.keys(modules)) {
  if (modules[moduleName].actions && modules[moduleName].actions.init) {
    store.dispatch(`${moduleName}/init`)
  }
}

export default store
