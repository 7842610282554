import axios from 'axios'
import { useRouter } from 'vue-router'
import fileDownload from "js-file-download"


export default function newLandingController() {
  const router = useRouter()
  const utmObject = () => {
    return {
      utm_source: router.currentRoute.value.query?.utm_source,
      utm_medium: router.currentRoute.value.query?.utm_medium,
      utm_campaign: router.currentRoute.value.query?.utm_campaign,
      utm_content: router.currentRoute.value.query?.utm_content,
      utm_term: router.currentRoute.value.query?.utm_term,
    }
  }
  const methods = {
    sendCallRequest: (config) => {
      axios({
        url: 'https://b24.ndfl.guru/rest/6/hnxkl6czz0lk0570/finoscope.ndflGuruFormRequest',
        method: 'POST',
        data: config.body,
      })
        .then(() => {
          config?.successCallback()
        })
        .catch((err) => {
          config?.errorCallback(err)
        })
    },
    putUtmToLocalStorage: () => {
      setTimeout(() => {
        localStorage.setItem(
          'ng_utm',
          JSON.stringify({
            utm: {
              utm_source: router.currentRoute.value.query?.utm_source,
              utm_medium: router.currentRoute.value.query?.utm_medium,
              utm_campaign: router.currentRoute.value.query?.utm_campaign,
              utm_content: router.currentRoute.value.query?.utm_content,
              utm_term: router.currentRoute.value.query?.utm_term,
            },
          })
        )
      }, 2000)
    },
    // getLandingPrices: () => {
    //   return axios({
    //       url: `${process.env.VUE_APP_SERVER_HOST}/api/prices`,
    //       method: 'GET',
    //       headers: {
    //         "Content-Type": "application/json",
    //         "Accept": "application/json",
    //         "Authorization": process.env.VUE_APP_PRICES_TOKEN
    //       },
    //     }).then((res) => {
    //       tariffs.splice(0, tariffs.length, ...res.data.tariffs)
    //       return tariffs.reduce((acc, item) => {
    //         const findItem = iconsClasses.find(i => i.tag === item?.tag)
    //         item.icon = findItem?.icon
    //         item.bg_class = findItem?.bg_class
    //         item.usualType = findItem?.usualType
    //         item.text = findItem?.text
    //         item.transactions = findItem?.transactions
    //         acc?.push(item)
    //         return acc
    //       }, [])
    //     }).catch((err) => {
    //       console.log(err)
    //     })
    // },
    getLandingSampleFile: (prefix, fileName) => {
      return axios({
        url: `${process.env.VUE_APP_SERVER_HOST}/api/${prefix}`,
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": process.env.VUE_APP_PRICES_TOKEN
        },
        responseType: 'blob'
      }).then((res) => {
        fileDownload(res.data, fileName)
      }).catch((err) => { console.log(err) })
    },
    getPriceList: () => {
      return axios({
        url: `${process.env.VUE_APP_SERVER_HOST}/api/price_list`,
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": process.env.VUE_APP_PRICES_TOKEN
        },
        responseType: 'blob'
      }).then((res) => {
        fileDownload(res.data, 'price_list')
      }).catch((err) => { console.log(err) })
    },
    sendDiscountRequest: (config) => {
      return new Promise((resolve, reject) => {
        axios({
          url: `https://b24.ndfl.guru/rest/6/hnxkl6czz0lk0570/finoscope.discountRequest`,
          method: 'POST',
          data: config.body,
        }).then((res) => {
          resolve(res)
        }).catch((err) => {
          reject(err)
        })
      })
    },
  }

  const sampleFiles = {
    cfr: {
      title: 'Отчёт о движении средств',
      methodPrefix: 'sample_cfr',
      fileName: 'ndfl_guru_cfr_sample.pdf',
      description: 'Обязательный для подачи в ФНС отчет о движении денежных средств и иных активов по зарубежным счетам.'
    },
    declaration: {
      title: 'Декларация',
      methodPrefix: 'sample_declaration',
      fileName: 'ndfl_guru_declaration_sample.pdf',
      description: 'Форма для загрузки декларации в личном кабинете налоговой.'
    },
    explain_note: {
      title: 'Пояснительная записка',
      methodPrefix: 'sample_exp_note',
      fileName: 'ndfl_guru_explanation_note_sample.pdf',
      description: 'Документ для инспектора, который помогает ему понять, что представлено в расчёте.'
    },
    calculation: {
      title: 'Расчёты',
      methodPrefix: 'sample_calculation',
      fileName: 'ndfl_guru_calculation_sample.pdf',
      description: 'Таблица с подробным расчетом операций, пересчитанных в рубли по курсу ЦБ на день сделки и ' +
                   'структурированных по типам финансовых инструментов.'
    }
  }

  return {
    methods,
    utmObject,
    sampleFiles,
  }
}
