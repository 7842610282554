import axios from '../plugins/axios'
import { useStore } from 'vuex'
import { computed } from 'vue'
import useRollbar from '@/plugins/rollbar'
import useInvoiceAsyncCallback from '@/controllers/service/InvoiceAsyncCallback'

export default function newInvoiceController(config = null) {
  const store = config?.store ?? useStore()
  const isAsync = computed(() => store.state.invoice.asyncInvoiceTimer)
  const notification = config?.notification ?? useRollbar()
  const GlobalEmitter = store.state.service.globalEmitter

  const asyncConfig = {
    update: (res) => {
      store.commit('invoice/updateCurrentInvoice', res.data)
    },
    updateTimer: (timer) => {
      store.commit('invoice/updateAsyncInvoiceTimer', timer)
    },
    clearTimer: (res) => {
      if (store.state.invoice.asyncInvoiceTimer) {
        GlobalEmitter.emit('invoice controller => invoice update event', res.data)
      }
      store.commit('invoice/clearAsyncInvoiceTimer')
    },
    method: (config) => {
      methods.show(config)
    },
    pathAsync: ['status', 'async'],
  }

  const asyncCallback = (res, config) => {
    store.commit('invoice/updateCurrentInvoice', res.data)
    if (res.data?.status?.async) {
      store.commit(
        'invoice/updateAsyncInvoiceTimer',
        setTimeout(() => {
          methods.show(config)
        }, 2500)
      )
      config.successCallback?.(res.data)
    } else {
      config.successCallback?.(res.data)
      store.commit('invoice/clearAsyncInvoiceTimer')
    }
  }

  const methods = {
    index: (config) => {
      axios({
        url: `/invoices?page=${config.page}`,
        method: 'GET',
      })
        .then((res) => {
          store.commit('invoice/updateInvoiceList', res.data?.invoices)
          config?.successCallback?.(res.data)
        })
        .catch((error) => {
          notification.rollbar(
            'error',
            'Ошибка получения списка заказов пользователя',
            JSON.stringify({
              error,
              errorInfo: error?.cause,
            })
          )
          config?.errorCallback?.(error)
        })
    },
    create: (config) => {
      axios({
        url: '/invoices',
        method: 'POST',
      })
        .then((res) => {
          config.successCallback?.(res.data)
        })
        .catch((error) => {
          notification.rollbar(
            'error',
            'Ошика создания нового заказа',
            JSON.stringify({
              error,
              errorInfo: error?.cause,
            })
          )
          config.errorCallback?.(error)
        })
    },
    show: (config) => {
      axios({
        url: `/invoices/${config.id}`,
        method: 'GET',
      })
        // .then((res) => useInvoiceAsyncCallback(res, config, asyncConfig))
        .then((res) => {
          asyncCallback(res, config)
          // config?.successCallback?.(res.data)
        })
        .catch((error) => {
          notification.rollbar(
            'error',
            `Ошибка при получении информации по заказу ${config.id}`,
            JSON.stringify({
              invoiceId: config.id,
              error,
              errorInfo: error?.cause,
            })
          )
          config.errorCallback?.(error)
        })
    },
    loadFile: (config) => {
      axios({
        url: `/invoices/${config.id}/file_upload`,
        method: 'PATCH',
        data: config.data,
      })
        .then((res) => useInvoiceAsyncCallback(res, config, asyncConfig))
        .then((r) => {
          config?.successCallback?.(r)
        })
        .catch((error) => {
          notification.rollbar(
            'error',
            `Ошибка при загрузке файлов в заказ ${config.id}`,
            JSON.stringify({
              invoiceId: config.id,
              error,
              errorInfo: error?.cause,
              requestBody: config.data,
            })
          )
          config.errorCallback?.(error)
        })
    },
    goodsSelect: (config) => {
      axios({
        url: `/invoices/${config.id}/goods_selection`,
        method: 'POST',
        data: config.body,
      })
        .then((res) => {
          config.successCallback?.(res.data)
        })
        .catch((error) => {
          notification.rollbar(
            'error',
            `Ошибка выбора товаров по заказу ${config.id}`,
            JSON.stringify({
              invoiceId: config.id,
              error,
              errorInfo: error?.cause,
              requestBody: config.body,
            })
          )
          config.errorCallback?.(error)
        })
    },
    surchargePayment: (config) => {
      axios({
        url: `/invoices/${config.id}/payment_surcharge`,
        method: 'POST',
      })
        .then((res) => {
          config.successCallback?.(res.data)
        })
        .catch((error) => {
          notification.rollbar(
            'error',
            `Ошибка выбора товаров по заказу ${config.id}`,
            JSON.stringify({
              invoiceId: config.id,
              error,
              errorInfo: error?.cause,
            })
          )
          config.errorCallback?.(error)
        })
    },
    createPersonalData: (config) => {
      axios({
        url: `/invoices/${config.id}/personal_data`,
        method: 'POST',
        data: config.body,
      })
        .then((res) => {
          config?.successCallback?.(res.data)
        })
        .catch((error) => {
          notification.rollbar(
            'error',
            'Ошибка при отправлении персональных данных',
            JSON.stringify({
              error,
              errorInfo: error?.cause,
              invoiceId: config.id,
              personalDataBody: config.body,
            })
          )
          config.errorCallback?.(error)
        })
    },
    getPersonalData: (config) => {
      return new Promise((resolve, reject) => {
        axios({
          url: `/invoices/${config.id}/personal_data`,
          method: 'GET',
        })
          .then((res) => {
            resolve(res.data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    getCurrentPersonalDataCard: (config) => {
      return new Promise((resolve, reject) => {
        axios({
          url: `/invoices/${config.id}/current_personal_data`,
          method: 'GET',
        })
          .then((res) => {
            resolve(res.data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    removePersonalDataCard: (config) => {
      axios({
        url: `/invoices/${config.id}/personal_data/${config.cardId}`,
        method: 'DELETE',
      })
        .then((res) => {
          config?.successCallback?.(res.data)
        })
        .catch((error) => {
          config.errorCallback?.(error)
        })
    },
    addPromocode: (config) => {
      axios({
        url: `/invoices/${config.id}/add_promocode`,
        method: 'PATCH',
        data: config.body,
      })
        .then(() => {
          methods.show(config)
        })
        .catch((error) => {
          config?.errorCallback?.(error?.cause)
        })
    },
    dropThankUPage: (config) => {
      return new Promise((resolve, reject) => {
        axios({
          url: `/invoices/${config.id}/drop_thank_you_page`,
          method: 'PATCH',
          data: config.data,
        })
          .then((res) => {
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    dropDiscountRequest: (config) => {
      return new Promise((resolve, reject) => {
        axios({
          url: `/invoices/${config.id}/set_discount_request`,
          method: 'PATCH',
          data: config.data,
        })
          .then((res) => {
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    createPreAddDeductions: (config) => {
      return new Promise((resolve, reject) => {
        return axios({
          url: `/invoices/${config.id}/pre_selection_add_incomes`,
          method: 'POST',
          data: config.body,
        })
          .then((res) => {
            console.log(res)
            resolve(res)
          })
          .catch((err) => {
            console.log(err)
            reject(err)
          })
      })
    },
  }

  const clearAllTimers = () => {
    store.commit('invoice/clearAsyncInvoiceTimer')
  }

  const getters = {
    index: computed(() => store.state.invoice.invoiceList),
    show: computed(() => store.state.invoice.currentInvoice),
    showScope: {
      prices: computed(() => store.state.invoice.currentInvoice?.all_price || {}),
      goods: computed(() => store.state.invoice.currentInvoice?.goods),
      yearToCalc: computed(() => store.state.invoice.currentInvoice?.year_to_calc || '2021'),
      status: computed(() => store.state.invoice.currentInvoice?.status),
      errors: computed(() => store.state.invoice.currentInvoice?.warning_and_error),
      surcharge: computed(() => store.state.invoice.currentInvoice?.surcharge),
      hasPromo: computed(() => Object.keys(store.state.invoice.currentInvoice).includes('promo')),
      payed: computed(() => store.state.invoice.currentInvoice?.pay_status),
      profileId: computed(() => store.state.invoice.currentInvoice?.profile_id),
      amountOperation: computed(
        () =>
          store.state.invoice.currentInvoice?.amount_operations || {
            Declaration: {},
          }
      ),
      preAddDeduction: computed(() => store.state.invoice.currentInvoice.pre_add_deduction),
    },
  }

  return {
    methods,
    getters,
    isAsync,
    clearAllTimers,
    asyncCallback,
  }
}
