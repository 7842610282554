import axios from '@/plugins/axios'
import { ref, reactive, computed, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'
import useRollbar from '@/plugins/rollbar'

export default function newDeclarationController(initId, config = null) {
  const urlName = 'declarations'
  const store = config?.store ?? useStore()
  const id = ref(initId)
  const timer = ref(null)
  const files = reactive([])
  const GlobalEmitter = store?.state?.service?.globalEmitter
  const notification = config?.notification ?? useRollbar()
  const isLoading = ref(false)

  const asyncCallback = (res, config) => {
    store.commit('invoice/updateCurrentDeclaration', res.data)
    if (res.data?.async) {
      store.commit(
        'invoice/updateAsyncDeclarationTimer',
        setTimeout(() => {
          methods.show(config)
        }, 2500)
      )
      config?.successCallback?.(res.data)
    } else {
      config?.successCallback?.(res.data)
      store.commit('invoice/clearAsyncDeclarationTimer')
    }
    GlobalEmitter.emit('declaration controller => declaration update event', res.data)
  }

  const methods = {
    show: (config) => {
      axios({
        url: `/${urlName}/${id.value}`,
        method: 'GET',
      })
        .then((res) => {
          asyncCallback(res, config)
        })
        .catch((error) => {
          notification.rollbar(
            'error',
            `Ошибка получения информации по декларации ${id.value}`,
            JSON.stringify({
              declarationId: id.value,
              error,
              errorInfo: error?.cause,
            })
          )
          config?.errorCallback?.(error)
        })
    },
    getTransfers: (config) => {
      axios({
        url: `${urlName}/${id.value}/transfers?page=${config.page}`,
        method: 'GET',
      })
        .then((res) => {
          // TODO Обработка
          store.commit('invoice/updateCurrentTransfers', res.data?.transfers || [])
          config?.successCallback?.(res.data)
        })
        .catch((error) => {
          notification.rollbar(
            'error',
            `Ошибка запроса get трансферов в декларации ${id.value}`,
            JSON.stringify({
              declarationId: id.value,
              error,
              errorInfo: error?.cause,
            })
          )
          config?.errorCallback?.(error)
        })
    },
    updateTransfers: (config) => {
      return new Promise((resolve, reject) => {
        axios({
          url: `${urlName}/${id.value}/transfers/resolve`,
          method: 'PATCH',
          data: config.body,
        })
          .then((res) => {
            resolve(res)
            // methods.show({
            //   successCallback: config?.successCallback?.(res.data),
            // })
          })
          .catch((error) => {
            reject(error)
            // notification.rollbar(
            //   'error',
            //   `Ошибка обновления трансферов в декларации ${id.value}`,
            //   JSON.stringify({
            //     declarationId: id.value,
            //     error,
            //     errorInfo: error?.cause,
            //     requestBody: config.body,
            //   })
            // )
            // config?.errorCallback?.(error)
          })
      })
    },
    getUnclosedShorts: (config) => {
      axios({
        url: `${urlName}/${id.value}/unclosed_shorts?page=${config.page}`,
        method: 'GET',
      })
        .then((res) => {
          // TODO Обработка
          store.commit('invoice/updateCurrentUnclosedShorts', res.data?.unclosed_shorts || [])
          config?.successCallback?.(res.data)
        })
        .catch((error) => {
          notification.rollbar(
            'error',
            `Ошибка запроса get шортов в декларации ${id.value}`,
            JSON.stringify({
              declarationId: id.value,
              error,
              errorInfo: error?.cause,
            })
          )
          config?.errorCallback?.(error)
        })
    },
    updateUnclosedShorts: (config) => {
      return new Promise((resolve, reject) => {
        axios({
          url: `${urlName}/${id.value}/unclosed_shorts/resolve`,
          method: 'PATCH',
          data: config.body,
        })
          .then((res) => {
            resolve(res)
            // methods.show({
            //   successCallback: config?.successCallback?.(res.data),
            // })
          })
          .catch((error) => {
            reject(error)
            // notification.rollbar(
            //   'error',
            //   `Ошибка обновления шортов в декларации ${id.value}`,
            //   JSON.stringify({
            //     declarationId: id.value,
            //     error,
            //     errorInfo: error?.cause,
            //     requestBody: config.body,
            //   })
            // )
            // config?.errorCallback?.(error)
          })
      })
    },
    getMissings: (config) => {
      axios({
        url: `${urlName}/${id.value}/missings?page=${config.page}`,
        method: 'GET',
      })
        .then((res) => {
          // TODO Обработка
          store.commit('invoice/updateCurrentMissings', res.data?.missings || [])
          config?.successCallback?.(res.data)
        })
        .catch((error) => {
          notification.rollbar(
            'error',
            `Ошибка запроса get миссингов в декларации ${id.value}`,
            JSON.stringify({
              declarationId: id.value,
              error,
              errorInfo: error?.cause,
            })
          )
          config?.errorCallback?.(error)
        })
    },
    updateMissings: (config) => {
      return new Promise((resolve, reject) => {
        axios({
          url: `${urlName}/${id.value}/missings/resolve`,
          method: 'PATCH',
          data: config.body,
        })
          .then((res) => {
            resolve(res)
            // methods.show({
            //   successCallback: config?.successCallback?.(res.data),
            // })
          })
          .catch((error) => {
            reject(error)
            // notification.rollbar(
            //   'error',
            //   `Ошибка обновления миссингов в декларации ${id.value}`,
            //   JSON.stringify({
            //     declarationId: id.value,
            //     error,
            //     errorInfo: error?.cause,
            //     requestBody: config.body,
            //   })
            // )
            // config?.errorCallback?.(error)
          })
      })
    },
    getResultFiles: () => {
      isLoading.value = true
      axios({
        url: `/${urlName}/${id.value}/result_files`,
        method: 'GET',
      })
        .then((res) => {
          if (res.data?.result_files?.length > 0) {
            files.splice(0, files.length, ...res.data.result_files)
            methods.stopTimeout()
            isLoading.value = false
          } else {
            timer.value = setTimeout(() => {
              methods.getResultFiles()
            }, 5000)
          }
          isLoading.value = false
        })
        .catch((error) => {
          notification.rollbar(
            'error',
            `Ошибка получения результирующих файлов в декларации ${id.value}`,
            JSON.stringify({
              declarationId: id.value,
              error,
              errorInfo: error?.cause,
            })
          )
        })
    },
    stopTimeout: () => {
      clearTimeout(timer.value)
      timer.value = null
    },
    getAdditionalIncomes: (config = {}) => {
      axios({
        url: `${urlName}/${id.value}/additional_incomes`,
        method: 'GET',
      })
        .then((res) => {
          config?.successCallback?.(res.data)
        })
        .catch((error) => {
          notification.rollbar(
            'error',
            `Ошибка при запросе дополнительных доходов в декларации ${id.value}`,
            JSON.stringify({
              declarationId: id.value,
              error,
              errorInfo: error?.cause,
            })
          )
          config?.successCallback?.(error)
        })
    },
    updateAdditionalIncomes: (config = {}) => {
      axios({
        url: `${urlName}/${id.value}/additional_incomes`,
        method: 'PATCH',
        data: config.body,
      })
        .then((res) => {
          config?.successCallback?.(res.data)
        })
        .catch((error) => {
          notification.rollbar(
            'error',
            `Ошибка при обновлении дополнительных доходов в декларации ${id.value}`,
            JSON.stringify({
              declarationId: id.value,
              error,
              errorInfo: error?.cause,
              requestBody: config.body,
            })
          )
          config?.errorCallback?.(error)
        })
    },
    getRefunds: (config = {}) => {
      axios({
        url: `${urlName}/${id.value}/deductions`,
      })
        .then((res) => {
          config?.successCallback?.(res.data)
        })
        .catch((error) => {
          notification.rollbar(
            'error',
            `Ошибка при запросе вычетов в декларации ${id.value}`,
            JSON.stringify({
              declarationId: id.value,
              error,
              errorInfo: error?.cause,
            })
          )
          config?.errorCallback?.(error)
        })
    },
    updateRefunds: (config = {}) => {
      axios({
        url: `${urlName}/${id.value}/deductions`,
        method: 'PATCH',
        data: config.body,
      })
        .then((res) => {
          config?.successCallback?.(res.data)
        })
        .catch((error) => {
          notification.rollbar(
            'error',
            `Ошибка при обновлении вычетов в декларации ${id.value}`,
            JSON.stringify({
              declarationId: id.value,
              error,
              errorInfo: error?.cause,
              requestBody: config.body,
            })
          )
          config?.errorCallback?.(error)
        })
    },
    getBankList: (val) => {
      const token = process.env.VUE_APP_DADATA_PUBLIC_KEY
      return new Promise ((resolve, reject) => {
        axios({
          url: `https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/bank`,
          method: 'POST',
          mode: 'cors',
          data: JSON.stringify({ query: val }),
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Token " + token
          },
        }).then((res) => {
          resolve(res)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    getCurrentBankAccount: () => {
      return new Promise((resolve, reject) => {
        axios({
          url: `/declarations/${id.value}/bank_to_refunds`,
          method: 'GET',
        }).then((res) => {
          resolve(res)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    createBankAccount: (data) => {
      return new Promise((resolve, reject) => {
        axios({
          url: `/declarations/${id.value}/bank_to_refunds`,
          method: 'POST',
          data
        }).then((res) => {
          resolve(res)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    updateBankAccount: (config) => {
      return new Promise((resolve, reject) => {
        axios({
          url: `/declarations/${id.value}/bank_to_refunds/${config.bank_id}`,
          method: 'PATCH',
          data: config.body
        }).then((res) => {
          resolve(res)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    createAddService: (config) => {
      return new Promise((resolve, reject) => {
        return axios({
          url: `/additional_service`,
          method: 'POST',
          data: config.body
        }).then((res) => {
          console.log(res)
          resolve(res)
        }).catch((err) => {
          console.log(err)
          reject(err)
        })
      })
    },
    getOKTMOIFNS: (val) => {
      return new Promise((resolve, reject) => {
        return axios({
          url: `/get_oktmo_ifns?user_input=${val}`,
          method: 'GET',
        }).then((res) => {
          resolve(res)
        }).catch((err) => {
          reject(err)
        })
      })
    },
  }
  const getter = {
    // TODo Временно
    show: computed(() => store.state.invoice.currentDeclaration),
    actions: computed(() => store.state.invoice.currentDeclaration?.actions || []),
    // show: computed(() => store.state.invoice.currentDeclaration),
    tms: {
      missings: computed(() => store.state.invoice.currentMissings),
      transfers: computed(() => store.state.invoice.currentTransfers),
      unclosedShorts: computed(() => store.state.invoice.currentUnclosedShorts),
    },
    additionalIncomes: computed(() => store.state.invoice.currentAdditionalIncomes),
  }

  onBeforeUnmount(() => {
    clearTimeout(timer.value)
  })

  return {
    id,
    methods,
    getter,
    timer,
    files,
    asyncCallback,
    isLoading
  }
}
