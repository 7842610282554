const TaxPlanIndex = () => import('@/views/account/TaxPlan/TaxPlanIndex')
const TaxPlanInfo = () => import('@/views/account/TaxPlan/TaxPlanInfo')

export default [
  {
    name: 'tax plan index',
    path: 'tax_plans',
    component: TaxPlanIndex,
  },
  {
    name: 'tax plan show',
    path: 'tax_plan/:id',
    component: TaxPlanInfo,
    props: true,
    meta: {
      middleware: [
        ({ from, to, next, store }) => {
          store
            .dispatch('taxPlan/getSelectTaxPlan', {
              id: to.params.id,
            })
            .then(() => {
              next()
            })
            .catch(() => {
              next({
                name: from.name,
                params: {
                  ...from.params,
                },
              })
            })
        },
      ],
    },
  },
]
