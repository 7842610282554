import { ref } from 'vue'

export default function useWindowWidth() {
  const width = ref(window.innerWidth)
  const updateWidth = () => {
    width.value = window.innerWidth
  }
  window.addEventListener('resize', updateWidth)

  const screenIsMobile = (val) => {
    return width.value < val
  }

  return {
    width,
    screenIsMobile,
  }
}
