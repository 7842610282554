import CheckAdminRoleMiddleware from '@/router/middlewares/CheckAdminRoleMiddleware'
import LoadPromocodeMiddleware from '@/router/Admin/LoadPromocodeMiddleware'
const Index = () => import('@/components/Admin/Promocode/Index')
const Show = () => import('@/components/Admin/Promocode/Show')

export default [
  {
    path: '/promocodes',
    name: 'promocode index',
    component: Index,
    meta: {
      middleware: [CheckAdminRoleMiddleware],
    },
  },
  {
    path: '/promocodes/:id',
    name: 'promocode show',
    component: Show,
    props: true,
    meta: {
      middleware: [CheckAdminRoleMiddleware, LoadPromocodeMiddleware],
    },
  },
]
