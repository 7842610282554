import { axiosWithoutToken } from '../plugins/axios'

export default function useChangePasswordRequest() {
  /**
   * Отправка заявки на смену пароля
   * @param config
   */
  const changePassword = (config) => {
    axiosWithoutToken({
      url: '/users/init_change_password',
      method: 'POST',
      data: config.body,
    })
      .then((res) => {
        config.successCallback?.(res.data)
      })
      .catch((err) => {
        config.errorCallback?.(err)
      })
  }
  /**
   * Отправка нового пароля
   * @param config
   */
  const resetPassword = (config) => {
    axiosWithoutToken({
      url: 'users/change_password',
      method: 'PUT',
      data: config.body,
    })
      .then((res) => {
        config.successCallback?.(res.data)
      })
      .catch((err) => {
        config.errorCallback?.(err)
      })
  }
  /**
   * Проверка токена
   * @param config
   */
  const checkPasswordToken = (config) => {
    axiosWithoutToken({
      url: 'users/check_reset_token?token=' + config?.body?.token,
      method: 'GET',
    })
      .then((res) => {
        if (res.status === 200) {
          config?.successCallback?.(res)
          return
        }
        Error('Неккоректный токен')
      })
      .catch((err) => {
        config?.errorCallback?.(err)
      })
  }

  return {
    changePassword,
    resetPassword,
    checkPasswordToken,
  }
}
