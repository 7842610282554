import CheckAdminRoleMiddleware from '@/router/middlewares/CheckAdminRoleMiddleware'
const brokersInfoIndex = () => import('@/components/Admin/BrokersInfo/BrokersInfoIndex')
const brokerShow = () => import('@/components/Admin/BrokersInfo/BrokerShow')

export default [
  {
    path: '/brokers',
    name: 'brokers index',
    component: brokersInfoIndex,
    meta: {
      middleware: [CheckAdminRoleMiddleware],
    },
  },
  {
    path: '/brokers/:id',
    name: 'broker show',
    component: brokerShow,
    props: true,
    meta: {
      middleware: [CheckAdminRoleMiddleware],
    },
  },
]
