import newInvoiceController from '@/controllers/InvoiceController'
import { useStore } from 'vuex'
import axios from '@/plugins/axios'
import useRollbar from '@/plugins/rollbar'
import { computed } from 'vue'
import { useRouter } from 'vue-router'

export default function newAdminInvoiceController(config) {
  const store = config?.store || useStore()
  const router = useRouter()
  const notification = useRollbar()
  const baseInvoiceController = newInvoiceController({
    store,
    notification,
  })

  const methods = {
    skipPayment: (config) => {
      axios({
        url: `/invoices/${config.id}/payment_admin`,
        method: 'GET',
      })
        .then((res) => {
          baseInvoiceController.asyncCallback(res, config)
        })
        .catch((error) => {
          config?.errorCallback?.(error)
        }).then(() => {
          methods.show({
            id: config.id,
            successCallback: () => {
              router.push({ name: 'invoice show', params: { id: config.id } })
            }
          })
      })
    },
    show: (config) => {
      axios({
        url: `/invoices/${config.id}`,
        method: 'GET',
      })
        .then((res) => {
          baseInvoiceController.asyncCallback(res, config)
        })
        .catch((error) => {
          config.errorCallback?.(error)
        })
    },
    searchInvoice: (config) => {
      axios({
        url: `/invoices/${config.id}`,
        method: 'GET',
      })
        .then((res) => {
          config?.successCallback(res)
        })
        .catch((error) => {
          config.errorCallback?.(error)
        })
    },
    getUserInvoices: (config) => {
      const genquery = config.profiles.map((i) => `q[profile_id_in][]=${i}`).join('&')
      axios({
        url: `/admin/invoices?${genquery}&q[s]=id desc&page=${config.page}`,
      })
        .then((res) => {
          store.commit('user/updateShowUserInvoices', res.data)
          config.successCallback?.(res.data)
        })
        .catch((error) => {
          notification.rollbar(
            'error',
            `Админ: ошибка получения заказов пользователя ${config.userId}`,
            JSON.stringify({
              error,
              errorInfo: error?.cause,
              config,
            })
          )
          config.errorCallback?.(error)
        })
    },
    recountDeclarationForCalcIncomes: (config) => {
      axios({
        url: `/admin/declarations/${config.declarationId}/rerender`,
        method: 'PATCH',
      })
        .then(() => {
          methods.show({ id: config.invoiceId })
          config.successCallback?.()
        })
        .catch((err) => [config.errorCallback?.(err)])
    },
  }
  const getters = {
    goods: computed(() => store.state.invoice.currentInvoice?.goods),
    status: computed(() => store.state.invoice.currentInvoice?.status),
    invoiceId: computed(() => store.state.invoice.currentInvoice?.id),
  }

  return {
    methods,
    getters,
  }
}
