import newDeclarationController from '@/controllers/DeclarationController'
import axios from '@/plugins/axios'

export default function useInvoiceRequest(res, store, next) {
  const GlobalEmitter = store.state.service.globalEmitter
  if (!Object.keys(res).includes('goods')) {
    next()
    return
  }
  if (Object.keys(res).includes('goods')) {
    if (Object.keys(res?.goods).includes('Declaration')) {
      const DeclarationController = newDeclarationController(res?.goods?.Declaration, {
        store
      })
      axios({
        url: `/declarations/${res?.goods?.Declaration}`,
        method: 'GET',
      }).then((res) => {
        DeclarationController.asyncCallback(res, {
          successCallback: () => {
            next()
          }
        })
      }).catch((err) => {
        console.log(err)
      })
    }
    if (Object.keys(res?.goods).includes('Cfr')) {
      axios({
        url: `cfrs/${res?.goods?.Cfr}`,
        method: 'GET',
      }).then((res) => {
        store.commit('invoice/updateCurrentCfr', res.data)
        GlobalEmitter.emit('cfr controller => cfr update event', res.data)
        next()
      }).catch((err) => {
        console.log(err)
      })
    }
    return
  }

  return {
  }
}
