// import Rollbar from 'rollbar'

export default function useRollbar() {
  const rollbarOptions = {
    accessToken: process.env.VUE_APP_ROllBAR_FRONTEND,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      client: {
        source_map_enabled: true,
      },
      environment: process.env.VUE_APP_ROLLBAR_ENVIRONMENT,
    },
  }
  // const rollbarFn = new Rollbar(rollbarOptions)
  const rollbarFn = () => {}

  const rollbar = (type, ...arg) => {
    if (process.env.NODE_ENV !== 'development') {
      console.error('Ошибка пользователя(интерфейс)', ...arg)
    } else {
      const user = localStorage.getItem('user')
      const args = [...arg, { user }]
      rollbarFn[type]?.(...args)
    }
  }
  return {
    rollbar,
    rollbarOptions,
  }
}
