import useChangePasswordRequest from '../use/useChangePasswordRequest'

function checkPasswordTokenMiddleware({ to, next }) {
  const { checkPasswordToken } = useChangePasswordRequest()
  checkPasswordToken({
    body: { token: to?.params?.token },
    successCallback: () => next(),
    errorCallback: (err) => {
      console.error(err)
    },
  })
}

export default checkPasswordTokenMiddleware
