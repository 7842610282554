<template>
  <el-popover
    :placement="screenIsMobile(1200) ? 'top' : 'right'"
    :trigger="screenIsMobile(1200) ? 'click' : 'hover'"
    class="margin-h--left-s"
    :width="width || 300"
  >
    <template #reference>
      <span>
        <el-icon :color="color" size="large" class="margin-h--left-s cursor-point"><QuestionFilled /></el-icon>
      </span>
    </template>
    <template #default>
      <slot></slot>
    </template>
  </el-popover>
</template>

<script>
import useWindowWidth from '@/use/useWindowWidth'

export default {
  name: 'NgGlobalQuestionPopover',
  props: {
    color: String,
    mobile: Boolean,
    width: Number,
  },
  setup() {
    const { screenIsMobile } = useWindowWidth()

    return {
      screenIsMobile,
    }
  },
}
</script>

<style scoped lang="sass"></style>
