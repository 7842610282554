const LoadFile = () => import('@/views/account/invoice/LoadFile')
const GoodsSelection = () => import('@/views/account/GoodsSelection')
const GoodsResultFiles = () => import('@/views/account/invoice/GoodsResultFiles')
const PreAddIncomesDeduction = () => import('@/components/Invoice/PreAddIncomesDeduction')

export default [
  {
    name: 'file_in_invoice',
    path: 'file_in_invoice',
    component: LoadFile,
    props: true,
  },
  {
    name: 'pre_add_deductions',
    path: 'pre_add_deductions',
    component: PreAddIncomesDeduction,
    props: true,
  },
  {
    name: 'good_select',
    path: 'good_select',
    component: GoodsSelection,
    props: true,
  },
  {
    name: 'goods result files',
    path: 'goods_result_files',
    component: GoodsResultFiles,
    props: true,
  },

]
