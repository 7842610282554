function UserIsNotLoggedInMiddleware({ next, to, store }) {
  if (!store.getters['user/is_login']) {
    store.commit('service/setToUrl', to)
    next({
      name: 'authorization',
    })
  } else {
    next()
  }
}

export default UserIsNotLoggedInMiddleware
