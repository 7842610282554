import axios from '@/plugins/axios'

export default {
  getDataSources: ({ commit, rootState }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `/profiles/${rootState.user.user.active_profile_id}/data_sources`,
      })
        .then((res) => {
          const sources = res.data?.data_sources || []
          commit('setDataSources', sources)
          resolve(sources)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getTaxPlan: ({ commit, rootState }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `/profiles/${rootState.user.user.active_profile_id}/tax_plans`,
        method: 'GET',
      })
        .then((res) => {
          const taxPlans = res.data?.tax_plans || []
          commit('setTaxPlansList', taxPlans)
          resolve(taxPlans)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getSelectTaxPlan: ({ commit, dispatch, rootState }, config) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `/profiles/${rootState.user.user.active_profile_id}/tax_plans/${config.id}`,
        method: 'GET',
      })
        .then((res) => {
          const taxPlans = res.data || null
          commit('setSelectTaxPlan', taxPlans)
          if (taxPlans.async) {
            commit(
              'updateTimer',
              setTimeout(() => {
                dispatch('getSelectTaxPlan', config)
              }, 5000)
            )
          }
          resolve(taxPlans)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  createNewTaxPlan: ({ rootState }, data) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `/profiles/${rootState.user.user.active_profile_id}/tax_plans`,
        method: 'POST',
        data,
      })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateTaxPlan: ({ rootState }, { id, data }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `/profiles/${rootState.user.user.active_profile_id}/tax_plans/${id}`,
        method: 'PATCH',
        data,
      })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  saveDataSource: ({ rootState }, { data }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `/profiles/${rootState.user.user.active_profile_id}/data_sources`,
        method: 'POST',
        data,
      })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getResultTable: (_, data) => {
    return new Promise((resolve, reject) => {
      const search = Object.keys(data?.search || {}).reduce((acc, key) => {
        return acc +`&${key}=${data?.search?.[key]}`
      }, '')
      axios({
        url: `profiles/${data.profileId}/tax_plans/${data.taxPlanId}/potential_results?page=${data.page}${search}`,
        method: 'GET',
      })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  setTickerAccounted: (_, data) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `profiles/${data.profileId}/tax_plans/${data.taxPlanId}/potential_results/${data.tickerId}`,
        method: 'PATCH',
        data: data.body,
      })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getTotalRow: (_, data) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `profiles/${data.profileId}/tax_plans/${data.taxPlanId}/potential_results/total_row`,
        method: 'GET',
      })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  setAllInFixed: (_, data) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `profiles/${data.profileId}/tax_plans/${data.taxPlanId}/potential_results/all_in`,
        method: 'PATCH',
      })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  setAllOutFixed: (_, data) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `profiles/${data.profileId}/tax_plans/${data.taxPlanId}/potential_results/all_out`,
        method: 'PATCH',
      })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
}
