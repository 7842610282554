import getters from './getters'
import actions from './actions'
import mutations from './mutations'
const state = {
  profile: null,
  profileList: [],
  stateError: null,
}
export default {
  state,
  getters,
  actions,
  mutations,
}
