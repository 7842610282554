export default function OnBoardingDescriber() {
  const onBoarding = [
    {
      foreign_dividend: `
        <h3>Иностранными эмитентами являются компании, зарегистрированные за пределами РФ.</h3>
        <p>
          Некоторые российские компании могут также иметь регистрацию за пределами РФ.
        </p>
        <p>
          Например: Тинькофф Групп TCSG – страна регистрации Кипр.
        </p>
        <p>
          Проверить можно по коду ISIN на сайте <a href="https://www.isin.ru" target="_blank">www.isin.ru.</a>
        </p>
      `,
    },
    {
      rental: `
        <h3>Если доход от аренды за рубежом, то укажите страну источника и страну зачисления выплаты.</h3>
        <p>Пример:</p>
        <p>Вы сдаете квартиру в Испании.</p>
        <p>Человек с латвийского счета оплачивает аренду на ваш латвийский счет.</p>
        <p>В этом случае страна источника и зачисления выплаты – Испании.</p>
      `,
    },
    {
      sale_transport: `
        <p>Продажа ранее минимального срока владения.</p>
      `,
    },
    {
      property_sale: `
        <p>
          Продажа ранее минимального срока владения.
        </p>
      `,
    },
    {
      unknown: `
        <p>
          Если вы не нашли в списке свою ситуацию, опишите её в поле ниже и укажите номер телефона.
        </p>
        <p>
          Наш специалист свяжется с вами для уточнений и запроса необходимых документов
        </p>
        <p>
          Стоимость внесения данных в декларацию от 1 000 руб.
        </p>
      `,
    },
    {
      two_ndfl_files: `
        <h3>Необходимо загрузить:</h3>
        <p>
            справку о доходах от работодателя, если в декларации планируете заявить социальные, имущественные вычеты,
            вычет в сумме внесенных средств на ИИС (тип А)
        </p
        <p>
            справку о доходах по брокерским счетам, если в декларации планируете произвести сальдирование
            прибыли/убытков между счетами.
        </p>
        <p>
            Справку необходимо скачать с ЛК ФНС в формате XML
            (<a href="https://ndfl.guru/instructions/kak-skachat-cpravku-o-dohodah-ranee-2-ndfl-iz-lichnogo-kabineta-nalogoplatelshchika-na-sayte-fns"
            target="_blank">
            инструкция</a> ).
        </p>
      `,
    },
    {
      assignment_of_rights: `
          <h3>Переуступка права</h3>
          <p>Это передача прав и обязанностей</p>
          <p>по договору участия в долевом</p>
          <p>строительстве жилья</p>
          <p>от одного первоначального участника строительства к другому</p>
          <p> (означает замену первоначального дольщика или покупателя).</p>
      `,
    },
    {
      social_refund: `
        <h3>Социальные вычеты</h3>
        <p>Максимальная сумма расходов по социальным вычетам — 120 000 рублей
        (не считая расходов на обучение детей, дорогостоящее лечение и благотворительность).
        </p>
      `,
    },
    {
      material_losses: `
        <h3>Убытки прошлых лет</h3>
        <p>Убытки должны быть получены вне ИИС.</p>
        <p>Учитываются только убытки, полученные на простых брокерских счетах.</p>
        <p>Если указать убытки на ИИС, то налоговая не примет декларацию и придется доплачивать налог.</p>
        <p>Убытки по зарубежным счетам должны быть ранее задекларированы, чтобы зачесть их в текущей декларации.</p>
      `,
    },
    {
      iis: `
        <h3>Инвестиционный вычет</h3>
        <p>Вычет по ИИС не уменьшает инвестиционную базу по ценным бумагам и ПФИ.</p>
        <p>НДФЛ, уплаченный с доходов по обычному брокерскому счету, нельзя вернуть с помощью вычета по ИИС типа А.</p>
      `,
    },
  ]
  const onBoardingIncomeFields = {
    foreign_dividend: {
      name_income: '<p>Краткое название котируемого инструмента.</p><p>Например: акции Apple Inc. - AAPL.</p>',
      oksm:
        '' +
        '<p>Страна регистрации компании, выпускающей ценную бумагу, по которой выплачены дивиденды. ' +
        'Можно найти по коду ISIN на сайте <a href="https://www.isin.ru" target="_blank">www.isin.ru.</a> Например: AAPL – ISIN US0378331005, страна эмитент США.</p>',
      oksm_broker:
        '<p>Страна регистрации вашего счета у брокера или в банке, на который поступил доход от дивидендов.</p>',
      amount: '<p>Сумма полученного дохода до удержания налогов.</p>',
      currency: '<p>Валюта, в которой был получен дивиденд.</p>',
      date: '<p>Дата зачисления дохода на счет согласно отчету.</p>',
      paid_tax_dividend:
        '<p>Сумма налога, уплаченная в иностранном государстве. В случае отсутствия уплаты налога за рубежом поставьте 0.</p>',
      paid_tax_dividend_date:
        '<p>Указывается в случае уплаты налога в дату, отличную от получения дохода от дивидендов. ' +
        'Если дата уплаты налога совпадает с датой выплаты дивиденда, поставьте галочку “Налог уплачен в дату получения дивидендов”. ' +
        'В случае отсутствия уплаты налога все равно поставьте галочку - Налог уплачен в дату получения дивидендов.</p>',
    },
    sale_transport: {
      income_info: '<p>Выбор зависит от страны местонахождения продаваемого имущества.</p>',
      estimate:
        '<p>Срок определяется по дате, с которой транспортное средство перешло в вашу собственность:<br>с даты подписания ' +
        'договора купли-продажи;<br>с даты смерти наследодателя;<br>с даты оформления договора дарения.</p>',
      purchase_by_yourself:
        '<p>Договор купли-продажи (собственный, дарителя – близкого родственника, наследодателя). ' +
        'Договор дарения не от близкого родственника. Близкий родственник - родители и дети, дедушки, бабушки и внуки, ' +
        'полнородные и неполнородные (имеющие общих отца или мать) братья и сестры.</p>',
      amount: '<p>Укажите полную сумму согласно договору.</p>',
      date: '<p>Дата фактического получения денежных средств за продажу.</p>',
      purchase_by_yourself_no:
        '<p>Применить имущественный вычет. Можно применить при отсутствии документально ' +
        'подтвержденных расходов (в пределах 250 000 рублей).</p>',
      name_income: '<p>Данные физического или юридического лица, являющегося покупателем согласно договору.</p>',
      oksm: '<p>Страна, резидентом которой является покупатель.</p>',
      oksm_broker: '<p>Страна регистрации вашего счета, на который поступил доход от продажи.</p>',
      transport_price: '<p>Документально подтвержденные расходы на приобретение данного транспортного средства.</p>',
      paid_tax_transport: '<p>Заполняется в случае уплаты налога в иностранном государстве.</p>',
      paid_tax_transport_date: '<p>Дата списания денежных средств со счета.</p>',
      expense_date: '<p>Дата списания денежных средств со счета.</p>',
      currency: '<p>Валюта, в которой был получен доход.</p>',
      expense_currency: '<p>Валюта, за которую было приобретено транспортное средство.</p>',
    },
    property_sale: {
      income_info: '<p>Выбор зависит от страны местонахождения продаваемого имущества.</p>',
      purchase_by_yourself:
        '<p>Договор купли-продажи (собственный, дарителя – близкого родственника, наследодателя). ' +
        'Договор дарения не от близкого родственника. Близкий родственник - родители и дети, дедушки, бабушки и внуки, ' +
        'полнородные и неполнородные (имеющие общих отца или мать) братья и сестры.</p>',
      paid_tax: '<p>Заполняется в случае уплаты налога в иностранном государстве.</p>',
      paid_tax_date: '<p>Дата списания денежных средств со счета.</p>',
      method:
        '<p>Близкий родственник - родители и дети, дедушки, бабушки и внуки, ' +
        'полнородные и неполнородные (имеющие общих отца или мать) братья и сестры.</p>',
      expired:
        '<p>Срок определяется по дате, с которой недвижимость перешла в вашу собственность:<br>с даты подписания ' +
        'договора купли-продажи;<br>с даты смерти наследодателя;<br>с даты оформления договора дарения.</p>',
      type:
        '<p>Тип имущества можно узнать по кадастровому номеру на сайте Росреестра ' +
        '<a href="https://www.rosreestr.gov.ru" target="_blank">www.rosreestr.gov.ru</a>.',
      amount: '<p>Укажите полную сумму согласно договору.</p>',
      date: '<p>Дата фактического получения денежных средств за продажу.</p>',
      amount_of_expenses:
        '<p>Сумма документально подтвержденных расходов на приобретение данной недвижимости (включая проценты по ипотеке). ' +
        'Ремонт и прочие расходы не учитываются. При отсутствии документально подтвержденных расходов можно применить имущественный ' +
        'вычет (в пределах 1 000 000 рублей - для жилой недвижимости и 250 000 рублей - для нежилой недвижимости).</p>',
      other_side: '<p>Данные физического или юридического лица, являющегося покупателем согласно договору.</p>',
      oksm: '<p>Страна, резидентом которой является покупатель.</p>',
      oksm_broker: '<p>Страна регистрации вашего счета, на который поступил доход от продажи.</p>',
      cadastral_number: '<p>Указан в договоре.</p>',
      cadastral_value:
        '<p>Можно узнать с помощью выписки из ЕГРН или на сайте Россреестра <a href="https://www.rosreestr.gov.ru" target="_blank">www.rosreestr.gov.ru</a></p>',
      share: '<p>Размер доли указан в договоре.</p>',
      expense_currency: '<p>Валюта, за которую была приобретена недвижимость.</p>',
      currency: '<p>Валюта, в которой был получен доход.</p>',
      expense_date: '<p>Дата фактического перечисления денежных средств за покупку данной недвижимости</p>',
    },
    assignment_of_rights: {
      amount: '<p>Укажите полную сумму согласно договору.</p>',
      date: '<p>Дата фактического получения денежных средств за продажу.</p>',
      amount_of_expenses: '<p>Сумма документально подтвержденных расходов на приобретение имущественных прав.</p>',
    },
    iis: {
      a_type:
        '<p>Сумма денежных средств, внесенных налогоплательщиком в налоговом периоде на индивидуальный инвестиционный ' +
        'счет, но не более 400 000 рублей.</p>',
      b_type:
        '<p>Предоставляется по окончании договора на ведение индивидуального инвестиционного счета при условии ' +
        'истечения не менее трех лет с даты заключения в сумме положительного финансового результата, полученного по ' +
        'операциям, учитываемым на данном счете.</p>',
      simplified: '<p>Предоставляется на основании заявления через личный кабинет налогоплательщика.</p>',
    },
    rental: {
      type:
        '<p>Для недвижимого имущества, находящегося в РФ, можно узнать по кадастровому номеру на сайте Росреестра ' +
        '<a href="https://www.rosreestr.gov.ru" target="_blank">www.rosreestr.gov.ru</a>.',
      income_info: '<p>Выбор зависит от страны местонахождения продаваемого имущества.</p>',
      other_side: '<p>Данные физического или юридического лица, являющегося арендатором согласно договору.</p>',
      oksm: '<p>Страна, резидентом которой является арендатор.</p>',
      oksm_broker: '<p>Страна регистрации вашего счета, на который поступил доход от аренды.</p>',
      periods: '<p>Каждое поступление средств за аренду нужно вносить отдельно, нажимая «+».</p>' +
        '<p>Пример: Вы сдаете квартиру и получаете арендные платежи ежемесячно, то нужно 12 раз внести доход по датам.</p>',
      amount: '<p>Укажите сумму за календарный месяц.</p>',
      date: '<p>Дата получения денег арендодателем.</p>',
      currency: '<p>Валюта, в которой был получен доход.</p>',
      paid_tax: '<p>Сумма налога, уплаченная в иностранном государстве..</p>',
      paid_tax_date: '<p>Указывается в случае уплаты налога в дату, отличную от получения дохода от аренды.</p>'
    },
    social_refund: {
      charity: '<p>К вычету принимаются только те пожертвования, которые были перечислены налогоплательщиком в адрес ' +
        'благотворительных организаций, в размере, не превышающем 25% суммы дохода, полученного в ' +
        'налоговом периоде и подлежащего налогообложению.</p>',
      med: '<p>Сумма, уплаченная налогоплательщиком в налоговом периоде за медицинские услуги, подтвержденная ' +
        'справкой с кодом 1, для себя, супруга, родителей, детей (в том числе усыновленных) в возрасте до 18 лет, ' +
        'подопечных в возрасте до 18 лет.</p>',
      exp_med: '<p>Сумма, уплаченная налогоплательщиком в налоговом периоде за медицинские услуги, ' +
        'подтвержденная справкой с кодом 2, для себя, супруга, родителей, детей (в том числе усыновленных) ' +
        'в возрасте до 18 лет, подопечных в возрасте до 18 лет.</p>',
      drugs: '<p>Сумма, уплаченная налогоплательщиком в налоговом периоде за лекарственные препараты для себя, супруга, ' +
        'родителей, детей (в том числе усыновленных) в возрасте до 18 лет, подопечных в возрасте до 18 лет. ' +
        'Подтверждается наличием рецепта по форме №107-1/у.</p>',
      self_learning: '<p>Сумма, уплаченная налогоплательщиком в налоговом периоде за своё обучение ' +
        '(любая форма обучения), брата или сестры в возрасте до 24 лет по очной форме обучения.</p>',
      children_learnings: '<p>Сумма, уплаченная налогоплательщиком в налоговом периоде за обучение своих детей в ' +
        'возрасте до 24 лет, обучение своих подопечных в возрасте до 18 лет по очной форме обучения (после прекращения ' +
        'опеки или попечительства в возрасте до 24 лет по очной форме обучения). Не более 50 000 рублей на каждого ' +
        'ребенка в общей сумме на обоих родителей (опекуна или попечителя).</p>',
      qualification_assessment: '<p>Сумма, уплаченная в налоговом периоде налогоплательщиком за прохождение ' +
        'независимой оценки своей квалификации.</p>',
      non_civil_pension_provide: '<p>Сумма уплаченных налогоплательщиком в налоговом периоде пенсионных взносов по ' +
        'договору (договорам) негосударственного пенсионного обеспечения в свою пользу и (или) в пользу членов семьи и ' +
        '(или) близких родственников в соответствии с Семейным кодексом Российской Федерации (супругов, родителей и ' +
        'детей, в том числе усыновителей и усыновленных, дедушки, бабушки и внуков, полнородных и неполнородных ' +
        '(имеющих общих отца или мать) братьев и сестер), детей-инвалидов, находящихся под опекой ' +
        '(попечительством).</p>',
      voluntary_pension_insurance: '<p>Сумма уплаченных налогоплательщиком в налоговом периоде страховых взносов по ' +
        'договору (договорам) добровольного пенсионного страхования в свою пользу и (или) в пользу супруга ' +
        '(в том числе вдовы, вдовца), родителей (в том числе усыновителей), детей-инвалидов ' +
        '(в том числе усыновленных, находящихся под опекой (попечительством).</p>',
      contributions_for_pension: '<p>Сумма уплаченных налогоплательщиком в налоговом периоде дополнительных ' +
        'страховых взносов на накопительную пенсию в соответствии с Федеральным законом «О дополнительных ' +
        'страховых взносах на накопительную пенсию и государственной поддержке формирования пенсионных накоплений».</p>',
      voluntary_insurance: '<p>Сумма страховых взносов, уплаченная налогоплательщиком в налоговом периоде по ' +
        'договорам добровольного страхования, предусматривающим оплату страховыми организациями исключительно ' +
        'медицинских услуг для себя, своих супруга, родителей, детей (в том числе усыновленных) в ' +
        'возрасте до 18 лет, подопечных в возрасте до 18 лет.</p>',
      live_insure: `<p>Сумма уплаченных налогоплательщиком в налоговом периоде взносов по договору (договорам): ' +
        'НПО, ДПС, ДСЖ и доп. страховых взносов на накопительную пенсию.</p>`,
      voluntary_live_insure: `<p>Сумма уплаченных налогоплательщиком в налоговом периоде страховых взносов
        по договору (договорам) добровольного страхования жизни, если такие договоры заключаются на срок не менее пяти
        лет в свою пользу и (или) в пользу супруга (в том числе вдовы, вдовца), родителей (в том числе усыновителей),
        детей (в том числе усыновленных, находящихся под опекой (попечительством).</p>`,
    },
  }

  const preAddOnBoarding = {
    sale_transport: {
      text: 'Укажите количество проданных транспортных средств. Транспортное средство (ТС) - автомобиль, мотоцикл, ' +
        'автобус, яхта, катер, гидроцикл и другие ТС. Налогом облагается продажа ранее минимального срока владения' +
        ' ТС (Минимальный срок владения для ТС составляет 3 года с даты покупки/дарения/в случае наследования с даты ' +
        'смерти наследодателя). Пример: Если продали один автомобиль, то укажите 1. Если продали автомобиль и ' +
        'мотоцикл, то укажите 2, и т.д.'
    },
    property_sale: {
      text: 'Укажите количество проданных объектов недвижимости. Объекты недвижимости - жилые и нежилые помещения: ' +
        'квартиры, апартаменты, комнаты, дома, земельные участки, машино-места, гаражи и т.п. Налогом облагается ' +
        'продажа ранее минимального срока владения имуществом (Минимальный срок владения для недвижимости составляет ' +
        '5 лет. В случае наследования/дарения/приватизации/передачи имущества по договору пожизненного содержания ' +
        'с иждивением или если единственное жилье, то минимальный срок владения для недвижимости составляет 3 года).'
    },
    rental: {
      text: 'Укажите количество сдаваемых в аренду объектов. ' +
        'Объекты недвижимости - жилые и нежилые помещения: квартиры, апартаменты, комнаты, дома, ' +
        'земельные участки, машино-места, гаражи и т.п.',
    },
    assignment_of_rights: {
      text: 'Укажите количество проданных объектов по договору долевого участия. ДДУ - это передача прав и ' +
        'обязанностей по договору от одного первоначального участника строительства к другому. Налогом облагается' +
        ' продажа ранее минимального срока владения объектом; срок владения по ДДУ отсчитывается с даты полной оплаты' +
        ' (Минимальный срок владения составляет 5 лет. Если это единственное жилье, то минимальный срок владения ' +
        'составляет 3 года).'
    },
    foreign_dividend: {
      text: `Укажите количество полученных дивидендов от иностранных эмитентов. Иностранными эмитентами являются
        компании, зарегистрированные за пределами РФ. Некоторые российские компании могут также иметь регистрацию
        за пределами РФ. Например: Тинькофф Групп TCSG – страна регистрации Кипр. Проверить можно по коду ISIN на
        сайте <a href="https://www.isin.ru/ru/foreign_isin/db/" target="_blank">www.isin.ru.</a>`
    },
    unknown: {
      text: 'Если вы не нашли в списке свою ситуацию, то укажите в данной строке количество видов дохода для внесения ' +
        'в декларацию.'
    },
    material_losses: {
      text: 'Укажите количество справок (от российских брокеров) и/или количество деклараций за прошлые периоды ' +
        '(в случае убытков по зарубежным брокерам), которые необходимо учесть в декларации. Убытки должны быть ' +
        'получены вне ИИС, т.е. по обычным брокерским счетам. Убытки по зарубежным счетам должны быть ранее ' +
        'задекларированы, чтобы учесть их в текущей декларации. Например, вы торговали через зарубежного брокера ' +
        'с 2015 по 2023 годы: в период 2015-2020 были убытки, а после прибыль (декларации по убыточным годам ' +
        'подавались); также у вас есть российский брокер, где была прибыль и лишь в 2022 был убыток (имеется 1 ' +
        'справка); в этом случае укажите 7 (6 убыточных периодов/годов по зарубежному брокеру и 1 справка об убытках' +
        ' по российскому брокеру).'
    },
    iis: {
      text: 'Напоминаем, что Вычет по ИИС (тип А) не уменьшает инвестиционную базу по ценным бумагам и ПФИ; и НДФЛ, ' +
        'уплаченный с доходов по обычному брокерскому счету, нельзя вернуть с помощью вычета по ИИС (тип А). Чтобы ' +
        'заявить право на данный вычет необходимо иметь доходы по основной налоговой базе (по заработной плате, от ' +
        'продажи и/или сдачи имущества в аренду и т.п.)'
    },
    property_deduction_build: {
      text: 'Данный вычет распространяется только на объекты жилой недвижимости: комнаты, квартиры и жилые дома ' +
        'полностью или доли в них (апартаменты сюда не относятся). Напоминаем, что данный вычет не уменьшает ' +
        'инвестиционную базу по ценным бумагам и ПФИ. Чтобы заявить право на данный вычет необходимо иметь доходы ' +
        'по основной налоговой базе (по заработной плате, от продажи и/или сдачи имущества в аренду и т.п.)'
    },
    property_deduction_mortgage: {
      text: 'Данный вычет распространяется только на объекты жилой недвижимости: комнаты, квартиры и жилые дома ' +
        'полностью или доли в них (апартаменты сюда не относятся). Напоминаем, что данный вычет не уменьшает ' +
        'инвестиционную базу по ценным бумагам и ПФИ. Чтобы заявить право на данный вычет необходимо иметь доходы ' +
        'по основной налоговой базе (по заработной плате, от продажи и/или сдачи имущества в аренду и т.п.)'
    },
    two_ndfl_files: {
      text: 'Укажите количество счетов, по которым вы хотите произвести сальдирование финансовых результатов. ' +
        'По отчетам брокеров, которые вы уже ранее загрузили в заказ, сальдирование будет произведено автоматически' +
        ' и без дополнительной оплаты, эти счета в данном пункте указывать не нужно! Сальдирование счетов возможно ' +
        'между разными брокерами (между российскими и зарубежными) и необходимо, чтобы уменьшить ' +
        'положительный финансовый результат у одного брокера на сумму отрицательного финансового результата у ' +
        'другого брокера. Например, вы торговали на Interactive Brockers и получили там прибыль, а у двух российских ' +
        'брокеров у вас в текущем году убытки; в этом случае укажите 2.'
    },
    med: {
      text: 'Сумма, уплаченная налогоплательщиком в налоговом периоде за медицинские услуги, подтвержденная ' +
        'справкой из мед.организации с кодом 1 (для себя, супруга, родителей, детей (в том числе усыновленных) ' +
        'в возрасте до 18 лет, подопечных в возрасте до 18 лет).'
    },
    exp_med: {
      text: 'Сумма, уплаченная налогоплательщиком в налоговом периоде за медицинские услуги, ' +
        'подтвержденная справкой из мед.учреждения с кодом 2 (для себя, супруга, родителей, детей ' +
        '(в том числе усыновленных) в возрасте до 18 лет, подопечных в возрасте до 18 лет).'
    },
    drugs: {
      text: 'Сумма, уплаченная налогоплательщиком в налоговом периоде за лекарственные препараты (для себя, супруга, ' +
        'родителей, детей (в том числе усыновленных) в возрасте до 18 лет, подопечных в возрасте до 18 лет). ' +
        'Подтверждается наличием рецепта по форме №107-1/у.'
    },
    fitness: {
      text: `Сумма, уплаченная налогоплательщиком в налоговом периоде за физкультурно-оздоровительные услуги,
      оказанные ему, его детям (в том числе усыновленным) в возрасте до 18 лет (до 24 лет при обучении по очной
      форме). При этом физкультурно-спортивная организация, ИП входят в утвержденный
      <a target="_blank"
      href="https://www.nalog.gov.ru/html/sites/www.rn40.nalog.ru/Novosti/Novosti/Spisok_fitnes_22%2007%202022.pdf">
      Перечень</a>.`
    },
    qualification_assessment: {
      text: 'Сумма, уплаченная в налоговом периоде налогоплательщиком за прохождение независимой оценки ' +
        'своей квалификации.'
    },
    children_learnings: {
      text: 'Сумма, уплаченная налогоплательщиком в налоговом периоде за обучение своих детей в возрасте до 24 лет, ' +
        'обучение своих подопечных в возрасте до 18 лет по очной форме обучения. Не более 50 000 рублей на каждого ' +
        'ребенка в общей сумме на обоих родителей (опекуна или попечителя).'
    },
    self_learning: {
      text: 'Сумма, уплаченная налогоплательщиком в налоговом периоде за своё обучение (любая форма обучения), брата ' +
        'или сестры в возрасте до 24 лет по очной форме обучения.'
    },
    voluntary_insurance: {
      text: 'Сумма страховых взносов, уплаченная налогоплательщиком в налоговом периоде по договорам добровольного ' +
        'страхования, предусматривающим оплату страховыми организациями исключительно медицинских услуг для себя, ' +
        'своих супруга, родителей, детей (в том числе усыновленных) в возрасте до 18 лет, подопечных в возрасте ' +
        'до 18 лет.'
    },
    contributions_for_pension: {
      text: 'Сумма уплаченных налогоплательщиком в налоговом периоде дополнительных страховых взносов на ' +
        'накопительную пенсию в соответствии с Федеральным законом «О дополнительных страховых взносах на ' +
        'накопительную пенсию и государственной поддержке формирования пенсионных накоплений».'
    },
    non_civil_pension_provide: {
      text: 'Сумма уплаченных налогоплательщиком в налоговом периоде пенсионных взносов по договору (договорам)' +
        ' негосударственного пенсионного обеспечения в свою пользу и (или) в пользу членов семьи и (или) близких' +
        ' родственников в соответствии с Семейным кодексом Российской Федерации (супругов, родителей и детей, в том' +
        ' числе усыновителей и усыновленных, дедушки, бабушки и внуков, полнородных и неполнородных (имеющих общих ' +
        'отца или мать) братьев и сестер), детей-инвалидов, находящихся под опекой (попечительством).'
    },
    voluntary_live_insure: {
      text: 'Сумма уплаченных налогоплательщиком в налоговом периоде страховых взносов по договору (договорам) ' +
        'добровольного страхования жизни, если такие договоры заключаются на срок не менее пяти лет в свою пользу' +
        ' и (или) в пользу супруга (в том числе вдовы, вдовца), родителей (в том числе усыновителей), детей ' +
        '(в том числе усыновленных, находящихся под опекой (попечительством).'
    },
    voluntary_pension_insurance: {
      text: 'Сумма уплаченных налогоплательщиком в налоговом периоде страховых взносов по договору (договорам) ' +
        'добровольного пенсионного страхования в свою пользу и (или) в пользу супруга (в том числе вдовы, вдовца), ' +
        'родителей (в том числе усыновителей), детей-инвалидов (в том числе усыновленных, находящихся под опекой ' +
        '(попечительством).'
    },
    charity: {
      text: 'К вычету принимаются только те пожертвования, которые были перечислены налогоплательщиком в адрес ' +
        'благотворительных, религиозных организаций и некоммерческих организаций, осуществляющим деятельность в ' +
        'области науки, культуры, физической культуры и спорта (за исключением проф.спорта), образования, ' +
        'просвещения, здравоохранения, защиты прав и свобод человека и гражданина.'
    },
    social_refund: {
      text: 'Напоминаем, что данный вычет не уменьшает инвестиционную базу по ценным бумагам и ПФИ. Чтобы заявить' +
        ' право на данный вычет необходимо иметь доходы по основной налоговой базе (по заработной плате, от продажи ' +
        'и/или сдачи имущества в аренду и т.п.)'
    },
  }
  return {
    onBoarding,
    onBoardingIncomeFields,
    preAddOnBoarding
  }
}
