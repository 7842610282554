export default {
  setTaxPlansList: (state, value) => {
    state.taxPlanList = value
  },
  setSelectTaxPlan: (state, value) => {
    state.selectTaxPlan = value
  },
  setDataSources: (state, value) => {
    state.dataSources = value
  },
  updateTimer: (state, ids) => {
    state.timer = ids
  },
  clearTimer: (state) => {
    clearTimeout(state.timer)
    state.timer = null
  },
}
