import actionGoogleAuth from './actions/actionGoogleAuth'
import actionRegistration from './actions/actionRegistration'
import actionLogin from './actions/actionLogin'
import actionExanteRegistration from '@/store/modules/user/actions/actionExanteRegistration'
import axios from '@/plugins/axios'

export default {
  actionGoogleAuth,
  actionRegistration,
  actionLogin,
  actionExanteRegistration,
  Action_LoadUserInfoFromLocalStore: ({ commit }, callback) => {
    const lsUser = localStorage.getItem('user')
    if (lsUser) {
      /**
       * @type {User}
       */
      const user = JSON.parse(lsUser)
      user.refresh_exp = new Date(user.refresh_exp)
      if (user?.refresh_exp < Date.now()) commit('signOut')
      else commit('update_user', user)
    }
    callback?.()
  },
  Action_SignOut: (store, callback) => {
    store.commit('signOut')
    callback?.()
  },
  getUserInfo: ({ state, commit }) => {
    axios({
      url: `/users/${state.user.user_id}`,
    }).then((res) => {
      commit('updateUserField', res.data)
    })
  },
  // Profile
  getProfiles: ({ commit }) => {
    commit('clearErrorState')
    return new Promise((resolve, reject) => {
      axios({
        url: '/profiles',
        method: 'GET',
      })
        .then((res) => {
          commit('setProfileList', res.data)
          resolve(res)
        })
        .catch((err) => {
          commit('setErrorState', err)
          reject(err)
        })
    })
  },
  createNewProfile: ({ commit }, { data }) => {
    commit('clearErrorState')
    return new Promise((resolve, reject) => {
      axios({
        url: '/profiles',
        method: 'POST',
        data,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          commit('setErrorState', err)
          reject(err)
        })
    })
  },
  updateProfile: ({ commit }, { id, data }) => {
    commit('clearErrorState')
    return new Promise((resolve, reject) => {
      axios({
        url: `/profiles/${id}`,
        method: 'PATCH',
        data,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          commit('setErrorState', err)
          reject(err)
        })
    })
  },
  destroyProfile: ({ commit }, { id }) => {
    commit('clearErrorState')
    return new Promise((resolve, reject) => {
      axios({
        url: `/profiles/${id}`,
        method: 'DELETE',
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          commit('setErrorState', err)
          reject(err)
        })
    })
  },
  selectActiveProfile: ({ commit }, { profileId }) => {
    commit('clearErrorState')
    return new Promise((resolve, reject) => {
      axios({
        url: `/profiles/${profileId}/set_as_active`,
        method: 'PATCH',
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          commit('setErrorState', err)
          reject(err)
        })
    })
  },
  // end Profile
}
