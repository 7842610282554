import axios from '@/plugins/axios'

export default function GetUserInfoMiddleware({ to, next, store, notification }) {
  axios({
    url: `/admin/users/${to.params.userId}`,
  })
    .then((res) => {
      store.commit('user/updateShowUser', res.data)
      return res.data
    })
    .then((user) => {
      return axios({
        url: `/admin/users/${user.user_id}/profiles`,
      })
    })
    .then((res) => {
      store.commit('user/updateShowUserProfiles', res.data.profiles)
      next()
    })
    .catch((error) => {
      notification.rollbar(
        'error',
        `Админ: ошибка получения информации по пользователю ${to.params.userId}`,
        JSON.stringify({
          error,
          errorInfo: error?.cause,
        })
      )
      next(false)
    })
}
