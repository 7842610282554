import axios from '@/plugins/axios'

export default {
  getProfiles: ({ commit }) => {
    commit('clearErrorState')
    return new Promise((resolve, reject) => {
      axios({
        url: '/profiles',
        method: 'GET',
      })
        .then((res) => {
          commit('setProfileList', res.data)
          resolve(res)
        })
        .catch((err) => {
          commit('setErrorState', err)
          reject(err)
        })
    })
  },
  createNewProfile: ({ commit }, { data }) => {
    commit('clearErrorState')
    return new Promise((resolve, reject) => {
      axios({
        url: '/profiles',
        method: 'POST',
        data,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          commit('setErrorState', err)
          reject(err)
        })
    })
  },
  updateProfile: ({ commit }, { id, data }) => {
    commit('clearErrorState')
    return new Promise((resolve, reject) => {
      axios({
        url: `/profiles/${id}`,
        method: 'PATCH',
        data,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          commit('setErrorState', err)
          reject(err)
        })
    })
  },
  destroyProfile: ({ commit }, { id }) => {
    commit('clearErrorState')
    return new Promise((resolve, reject) => {
      axios({
        url: `/profiles/${id}`,
        method: 'DELETE',
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          commit('setErrorState', err)
          reject(err)
        })
    })
  },
  selectActiveProfile: ({ commit }, { profileId }) => {
    commit('clearErrorState')
    return new Promise((resolve, reject) => {
      axios({
        url: `/profiles/${profileId}/set_as_active`,
        method: 'PATCH',
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          commit('setErrorState', err)
          reject(err)
        })
    })
  },
}
