import axios from 'axios'
import router from '../router'
import store from '../store'

const axiosConfig = {
  baseURL: `${process.env.VUE_APP_SERVER_HOST || ''}/api`,
  headers: {
    'Content-Type': 'application/json',
    accept: 'application/json',
  },
}

const errHandler = (err) => {
  if (err.response.status === 401 || err.response.status === 403) {
    store.dispatch('user/Action_SignOut', () => {
      router.push({
        name: 'authorization',
      })
    })
  }
  if (process.env.VUE_APP_SERVER_HOST === 'https://app.ndfl.guru') {
    if (
      err.response.status === 500 ||
      err.response.status === 502 ||
      err.response.status === 503 ||
      err.response.status === 504
    ) {
      setTimeout(() => {
        router.push({
          name: '500',
        })
      }, 100)
    }
  }
}

/**
 * Вызов без токена
 * @param config
 * @returns {Promise}
 */
export const axiosWithoutToken = (config) => {
  const a = axios.create(axiosConfig)
  return a(config)
}

/**
 * Вызов с авторизацией
 * @param config
 * @returns {Promise}
 */
export default (config) => {
  return new Promise((resolve, reject) => {
    const lsUser = localStorage.getItem('user')
    if (!lsUser) return reject(new Error('Нет информации о пользователе'))
    /**
     * @type {User}
     */
    const user = JSON.parse(lsUser)
    user.refresh_exp = new Date(user.refresh_exp)
    if (user.refresh_exp < Date.now()) reject(new Error('Итекло время сессии'))
    const refreshAxios = axios.create(axiosConfig)
    refreshAxios
      .request({
        url: '/users/change_token',
        method: 'PATCH',
        data: {
          refresh_token: user.refresh_token,
        },
      })
      .then(
        /**
         * @param data {RefreshTokenResponse}
         */
        ({ data }) => {
          const axiosWithToken = {
            baseURL: axiosConfig.baseURL,
            headers: {
              Authorization: data.access_token,
              ...axiosConfig.headers,
            },
          }
          const a = axios.create(axiosWithToken)
          a(config)
            .then((res) => resolve(res))
            .catch((err) => {
              if (process.env.NODE_ENV === 'development') console.error(err)
              errHandler(err)
              reject(
                new Error('Ошибка при выполнения запроса', {
                  cause: err.response,
                })
              )
            })
        }
      )
      .catch((err) => {
        errHandler(err)
        reject(new Error('Ошибка запроса change_token', { cause: err.response }))
      })
  })
}
