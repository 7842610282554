import getters from './getters'
import actions from './actions'
import mutations from './mutations'
const state = {
  taxPlanList: [],
  selectTaxPlan: null,
  dataSources: [],
  timer: null,
}
export default {
  state,
  getters,
  actions,
  mutations,
}
