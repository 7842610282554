<template>
  <div
    v-for="brokerName in keys(errors)"
    :key="brokerName"
    :class="type === 'error' ? 'err-box' : 'war-box'"
    class="margin-v-m padding--20 bg-red border"
  >
    <div class="line-height">
      <div class="flex-box flex-box--align-c margin-v-xs">
        <el-icon color="#FF9A9D" size="large" class="margin-h--right-10">
          <WarningFilled />
        </el-icon>
        <h3>Возникла ошибка</h3>
      </div>
      <p class="margin-top-s">Проблема в загруженных файлах.</p>
      <div class="margin-top-s">
        <ul>
          <li>Повторно скачайте отчеты брокера, строго следуя инструкции.</li>
          <li>Удалите загруженные файлы.</li>
          <li>Загрузите новые отчеты брокера в этот заказ.</li>
        </ul>
      </div>
    </div>
  </div>
  <slot></slot>
</template>

<script>
import { computed } from 'vue'
import { isEmpty } from 'lodash'
import newInvoiceController from '@/controllers/InvoiceController'

export default {
  name: 'NgGlobalErrWarning',
  props: {
    errors: Object,
    warnings: Object,
    type: String,
  },
  setup(props) {
    const InvoiceController = newInvoiceController()
    const errDescription = {
      not_custom_statement: 'Формат загруженного отчета не соответствует необходимым требованиям.',
      current_year:
        'Загруженные вами файлы не содержат информации о сделках в отчетном периоде. Пожалуйста, загрузите отчеты за предыдущие года',
    }

    const brokersName = {
      interactive_broker: 'Interactive Brokers',
      freedom_finance: 'Freedom Finance',
      workers: 'Системная ошибка',
      all_brokers: 'Файлы брокеров',
    }
    const hasErrors = computed(() => !isEmpty(props.errors))
    const hasWarnings = computed(() => !isEmpty(props.warnings))
    const payed = computed(() => InvoiceController.getters.showScope.payed.value)
    const keys = (val) => Object.keys(val)
    return {
      errDescription,
      brokersName,
      hasErrors,
      hasWarnings,
      keys,
      payed,
    }
  },
}
</script>

<style scoped lang="sass">
</style>
