<template>
  <div class="flex-box flex-box--center flex-box--sa timer">
    <div>
      <p class="margin-v-xs">{{ timer.days }}</p>
      <h3 class="font-weight--extra font-color--gray">Дней</h3>
    </div>
    <div class="margin-h--left-s">
      <p class="margin-v-xs">{{ timer.hours }}</p>
      <h3 class="font-weight--extra font-color--gray">Часов</h3>
    </div>
    <div class="margin-h--left-s">
      <p class="margin-v-xs">{{ timer.minutes }}</p>
      <h3 class="font-weight--extra font-color--gray">Минут</h3>
    </div>
    <div class="margin-h--left-s">
      <p class="margin-v-xs">{{ timer.seconds }}</p>
      <h3 class="font-weight--extra font-color--gray">Секунд</h3>
    </div>
  </div>
</template>

<script>
import useTimer from '@/use/useTimer'

export default {
  name: 'NgGlobalTimer',
  setup() {
    return {
      ...useTimer('2022-04-01 00:00'),
    }
  },
}
</script>

<style lang="sass" scoped>
p
  font-size: 32px
  font-weight: 700
  color: #4B60CE
</style>
