import { useGtag } from 'vue-gtag-next'

export default function useGtagManager() {
  const { event: googleEvent } = useGtag()
  const fbEvent = (name, config) => {
    // eslint-disable-next-line
    fbq('track', name, config)
  }
  const event = (name, config) => {
    if (process.env.VUE_APP_SERVER_HOST === 'https://dev.ndfl.guru') {
      console.log(`GtagEvent: ${name}`, config)
    } else {
      googleEvent(name, config)
      // eslint-disable-next-line
      ym(54601003,'reachGoal', name, config)
      switch (name) {
        case 'completeRegistration':
          // eslint-disable-next-line
          VK.Goal(53059428)
          fbEvent('CompleteRegistration', config)
          break
        case 'purchase':
          // eslint-disable-next-line
          VK.Goal(53059575)
          fbEvent('Purchase', config)
          break
        case 'lead':
          // eslint-disable-next-line
          VK.Goal(53059738)
          fbEvent('Lead', config)
          break
        case 'initiateCheckout':
          // eslint-disable-next-line
          VK.Goal(53526987)
          fbEvent('InitiateCheckout', config)
          break
      }
    }
  }


  return {
    event
  }
}
