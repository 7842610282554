import getters from './getters'
import actions from './actions'
import mutations from './mutations'
const state = {
  /**
   * @type {User | undefined}
   */
  user: undefined,
  showUser: null,
  showUserInvoices: null,
  showUserProfiles: null,
  // Profile
  profile: null,
  profileList: [],
  stateError: null,
  exanteRegToken: null,
  exanteError: null,
  // end Profile
}
export default {
  state,
  getters,
  actions,
  mutations,
}
