import EventEmitter from 'events'

export default {
  selectInvoiceActivePage: (state, data) => (state.invoiceActivePage = data),
  selectDeclarationActiveStep: (state, data) => (state.declarationActiveStep = data),
  createGlobalEmitter: (state) => (state.globalEmitter = new EventEmitter()),
  addListenerInGlobalEmitter: (state, config) => {
    state.globalEmitter.on(config.eventName, config?.fn)
  },
  removeListenerInGlobalEmitter: (state, config) => {
    state.globalEmitter.removeListener(config.eventName, config?.fn)
  },
  selectCfrActiveStep: (state, data) => (state.cfrActiveStep = data),
  toggleRedirectLoader: (state, data) => (state.redirectLoader = data),
  setToUrl: (state, toInfo) => (state.toUrl = toInfo),
}
