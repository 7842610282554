import newPromocodeController from '@/components/Admin/Promocode/PromocodeController'

export default function LoadPromocodeMiddleware({ from, to, next, store, notification }) {
  const PromocodeController = newPromocodeController(to.params.id, {
    store,
    notification,
  })
  PromocodeController.methods.show({
    id: to.params.id,
    successCallback: () => {
      next()
    },
    errorCallback: () => {
      next({
        name: from.name,
      })
    },
  })
}
