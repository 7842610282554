import useRegistrationMethod from './useRegistrationMethod'
import useLoginMethod from './useLoginMethod'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import useGoogleAuth from './useGoogleAuth'

/**
 *
 * @returns {{registration: (function(): {form: Form, auth: Function}|*), login: (function(): {form: Form, auth: Function}|*), googe_auth: (function(): *|{handleClickGetAuth: function(): Promise<void>}), signOut: (function(): {signOut: signOut})}}
 */
export default function useAuthController() {
  const store = useStore()
  const router = useRouter()

  const signOut = () => {
    store.dispatch('user/Action_SignOut', () => {
      router.push({
        name: 'authorization',
      })
    })
  }

  return {
    registration: () => useRegistrationMethod(),
    login: () => useLoginMethod(),
    googe_auth: () => useGoogleAuth(),
    signOut: () => ({ signOut }),
  }
}
