import getters from './getters'
import actions from './actions'
import mutations from './mutations'
const state = {
  invoiceList: [],
  currentInvoice: null,
  currentMissings: [],
  currentTransfers: [],
  currentUnclosedShorts: [],
  currentDeclaration: null,
  asyncInvoiceTimer: undefined,
  asyncDeclarationTimer: undefined,
  currentAdditionalIncomes: [],
  currentCfr: null,
  activePromocode: null,
  promocodeList: [],
  currentInvoiceVersion: null
}
export default {
  state,
  getters,
  actions,
  mutations,
}
