<template>
  <div>
    <p v-if="label" class="label font-weight--semi font-color--deep-gray">
      {{ label }}
    </p>
    <div class="form-slot">
      <slot />
      <el-popover
        placement="bottom"
        :trigger="screenIsMobile(1200) ? 'click' : 'hover'"
        class="margin-h--left-s"
        :width="300"
        v-if="!model?.valid && model?.touched"
      >
        <template #reference>
          <span class="form-warning">
            <el-icon color="#FF9A9D" size="large" class="margin-h--left-s cursor-point"><WarningFilled /></el-icon>
          </span>
        </template>
        <p v-if="model" :class="{ 'rb-form-error-hide': model?.valid || !model?.touched }" class="font-color--red" style="word-break: break-word">
          {{ model?.errors[0] || 'нет ошибок' }}
        </p>
      </el-popover>
      <!--      <span class="form-warning" v-if="model?.valid && model?.touched">-->
      <!--        <el-icon color="#43d2cc" size="large" class="margin-h&#45;&#45;left-s"><CircleCheckFilled /></el-icon>-->
      <!--      </span>-->
    </div>
  </div>
</template>

<script>
import useWindowWidth from '@/use/useWindowWidth'

export default {
  name: 'NgGlobalFormItem',
  props: {
    label: String,
    model: Object,
  },
  setup() {
    const { screenIsMobile } = useWindowWidth()

    return {
      screenIsMobile,
    }
  },
}
</script>

<style scoped lang="sass">
//.label
//  text-align: right
//  vertical-align: middle
//  float: left
//  box-sizing: border-box
.rb-form-error
  line-height: 14px
  opacity: 1
  margin-bottom: 10px
  &-hide
    opacity: 0 !important
.form-slot
  position: relative
.form-warning
  position: absolute
  right: -5px
  top: -5px
</style>
