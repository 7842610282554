import { axiosWithoutToken } from '../../../../plugins/axios'

/**
 *
 * @param store
 * @param config {request_config}
 */
export default (store, config) => {
  axiosWithoutToken({
    url: '/users/google_auth',
    method: 'POST',
    data: config.body,
  })
    .then((res) => {
      const user = res.data
      store.commit('update_user', user)
      config?.success_callback?.(user)
    })
    .catch((err) => config?.error_callback?.(err))
}
