import { inject } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import useGtagManager from '@/use/useGtagManager'

export default function useGoogleAuth() {
  const gAuth = inject('GoogleAuth')
  const { event } = useGtagManager()
  const router = useRouter()
  const store = useStore()
  const getGoogleClientId = (name) => {
    const matches = document.cookie.match(
      new RegExp(
        '(?:^|; )' +
          // eslint-disable-next-line
          name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
          '=([^;]*)'
      )
    )
    return matches ? decodeURIComponent(matches[1]) : undefined
  }
  // TODO отправлять текущий ga при авторизации

  const handleClickGetAuth = async () => {
    try {
      const authCode = await gAuth.getAuthCode()
      /**
       * @type {actionGoogleAuth}
       */
      store.dispatch('user/actionGoogleAuth', {
        body: {
          auth_code: authCode,
          ...JSON.parse(localStorage.getItem('ng_utm')),
          google_client_id: getGoogleClientId('_ga'),
        },
        success_callback: () => {
          event('completeRegistration')
          if (store.state.service.toUrl !== undefined) {
            router.push({
              path: '' + store.state.service.toUrl.path,
            })
          } else {
            router.push({
              name: 'account home',
            })
          }
        },
        error_callback: (err) => {
          throw err
        },
      })
    } catch (error) {
      // TODO Обработчик ошибок
      console.error(error)
    }
  }

  return {
    handleClickGetAuth,
  }
}
