<script>
import useAuthController from '../use/AuthController/useAuthController'

export default {
  name: 'NgGlobalGoogleAuthenticateButton',
  props: {
    text: String
  },
  setup() {
    return {
      ...useAuthController().googe_auth(),
    }
  },
}
</script>

<template>
  <button class="g-button button-main" @click="handleClickGetAuth">
    <img src="@/assets/g-logo.png" width="18" class="margin-h--right-10" alt="logo" />
    {{ text }}
  </button>
</template>

<style scoped lang="sass">
.g-button
  padding: 12px
  border: 1px solid transparentize(#EFEFEF, .7)
  border-radius: 6px
  background-color: white
@media(max-width: 767px)
  .g-button
    width: 100%
</style>
