import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { ElMessageBox } from 'element-plus'
import { h } from 'vue'
import useGtagManager from '@/use/useGtagManager'
/**
 * @param config
 * @param config.form
 * @param config.error_message {String} - Текст ошибки запросов
 * @param config.method {String} - Метод стора
 * @return {{auth: function}}
 */
export default function useAuthMethods(config) {
  const getGoogleClientId = (name) => {
    const matches = document.cookie.match(
      new RegExp(
        '(?:^|; )' +
          // eslint-disable-next-line
          name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
          '=([^;]*)'
      )
    )
    return matches ? decodeURIComponent(matches[1]) : undefined
  }
  const store = useStore()
  const router = useRouter()
  const { event } = useGtagManager()
  const labelFields = {
    email: 'Почта',
    password: ' Пароль',
    confirm_password: ' Подтверждение пароля',
    confirm_agreement: ' Пользовательское соглашение',
  }

  const auth = () => {
    config.form.validate()
    if (!config.form.valid.value) {
      const notValidFields = Object.keys(config.form).reduce((acc, item) => {
        if (Object.keys(config?.form[item])?.includes('blur')) {
          if (!config?.form[item]?.valid) {
            acc.push(labelFields[item])
          }
        }
        return acc
      }, [])
      ElMessageBox({
        title: 'Валидация не пройдена',
        message: h('p', null, [h('span', null, `Заполните поля ${notValidFields} верно.`)]),
        confirmButtonText: 'Понятно',
        type: 'error',
      })
      console.log(notValidFields)
      console.log(config.form)
      return
    }
    const data = {
      body: {
        ...config.form.toJson(),
        ...JSON.parse(localStorage.getItem('ng_utm')),
        google_client_id: getGoogleClientId('_ga'),
      },
      error_callback: (err) => {
        console.error(err)
        ElMessageBox({
          title: `${err.response.status} Вы не авторизованы`,
          message: h('p', null, [h('span', null, 'Пароль или логин указаны не верно')]),
          confirmButtonText: 'Понятно',
          type: 'error',
        })
      },
      success_callback: () => {
        event('completeRegistration')
        if (store.state.service.toUrl !== undefined) {
          router.push({
            path: '' + store.state.service.toUrl.path,
          })
        } else {
          router.push({
            name: 'account home',
          })
        }
      },
    }
    // console.log(data)
    store.dispatch(config.method, data)
  }

  return {
    auth,
    getGoogleClientId,
  }
}
