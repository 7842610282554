import { onUnmounted, reactive } from 'vue'

export default function useTimer(endDateTime) {
  const endDate = new Date(endDateTime.replace(/-/g, '/'))
  const jsTimer = null
  const timer = reactive({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  })

  const calcDif = () => {
    const dif = endDate - Date.now()
    let delta = Math.abs(dif) / 1000
    timer.days = Math.floor(delta / 86400)
    delta -= timer.days * 86400
    timer.hours = Math.floor(delta / 3600) % 24
    delta -= timer.hours * 3600
    timer.minutes = Math.floor(delta / 60) % 60
    delta -= timer.minutes * 60
    timer.seconds = Math.floor(delta % 60)
  }

  const init = () => {
    calcDif()
    setTimeout(() => {
      init()
    }, 1000)
  }
  init()
  const clearTimer = () => {
    clearTimeout(jsTimer)
  }
  onUnmounted(() => {
    clearTimer()
  })

  return {
    endDate,
    timer,
  }
}
