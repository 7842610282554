export default {
  /**
   * @return {User|undefined}
   */
  get_user: (state) => state.user,
  /**
   * @return {boolean}
   */
  is_login: (state) => !!state.user && state.user?.refresh_exp > Date.now(),
  /**
   * @return {boolean}
   */
  is_actual_token: (state) => state.user?.access_exp > Date.now(),
  getActiveProfileName: (store) => {
    const profiles = store.profileList?.profiles || []
    if (profiles.length === 0) return null
    const filter = profiles.filter((item) => item.active)
    if (filter.length === 0 || filter.length > 1) return null
    return filter[0]
  },
  hasExanteRegToken: (state) => {
    if (state.exanteRegToken === null) return false
    return true
  },
  getExanteRegToken: (state) => state.exanteRegToken,
}
