/**
 * @typedef notifications
 * @type {object}
 * @property {errorMessage} errorMessage
 */
import { ElMessage, ElMessageBox, ElNotification } from 'element-plus'
import useRollbar from '@/plugins/rollbar'
import { h } from 'vue'

export default {
  install: (app) => {
    const successMessageBox = (config) => {
      ElMessageBox({
        title: config.title,
        message: config.message,
        type: 'success',
        confirmButtonText: 'Понятно',
      })
        .then(() => {
          config?.confirmCallback?.()
        })
        .catch(() => {
          config?.cancelCallback?.()
        })
    }
    /**
     * @typedef NotificationErrorMessage
     * @param config
     * @param config.error
     * @param config.message - текст ошибки для пользователя
     * @param config.confirmCallback - коллбек закрытия сообщения ошибки
     */
    const errorMessageBox = (config) => {
      ElMessageBox.alert(config.message, 'Ошибка', {
        confirmButtonText: 'Понятно',
        type: 'error',
      }).then(() => {
        config.confirmCallback?.()
      })
    }

    const sideNotification = (config) => {
      ElNotification({
        title: config.title,
        message: h('i', config.message),
        type: config.type ? config.type : 'success'
      })
    }

    const confirmMessageBox = (config) => {
      ElMessageBox.confirm(config.message, {
        confirmButtonText: config.okButton,
        cancelButtonText: config.cancelButton,
        type: 'warning',
      })
        .then(() => {
          config.confirmCallback?.()
        })
        .catch(() => {
          config.cancelCallback?.()
        })
    }

    const alert = {
      infoMessage: (msg) => {
        ElMessage.info(msg)
      },
      errorMessage: (msg) => {
        ElMessage.error(msg)
      },
      successMessage: (msg) => {
        ElMessage.success(msg)
      },
      warningMessage: (msg) => {
        ElMessage.warning(msg)
      },
    }
    const notifications = {
      errorMessageBox,
      successMessageBox,
      ...useRollbar(),
      alert,
      ElMessageBox,
      ElMessage,
      confirmMessageBox,
      sideNotification,
    }
    app.config.globalProperties.$notification = notifications
    app.provide('notification', notifications)
  },
}
