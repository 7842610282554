import { reactive, ref, watch } from 'vue'
import { useFormField } from './useFormField'
import EventEmitter from 'events'

/**
 * @typedef ValidatorFunction
 * @type Function
 * @property val {*}
 * @return {boolean}
 */

/**
 * @typedef FieldInit
 * @type {Object}
 * @property value {anu} - значение
 * @property validators {ValidationFunction}
 */

/**
 * @typedef Form
 * @type {object}
 * @property valid {bool}
 * @property validate {function}
 * @property toJson {function}
 * @property clean {function}
 * @property emitter {EventEmitter}
 * @property updateFieldValidation {Function}
 * @property allTouched {Function}
 */

/**
 * @param init
 * @returns {Form}
 */
export function useForm(init) {
  const form = reactive({})
  const valid = ref(true)
  class Emitter extends EventEmitter {}
  const emitter = new Emitter()
  const fieldValidation = ref([])
  const updateFieldValidation = (val) => {
    fieldValidation.value.splice(0, fieldValidation.value.length)
    fieldValidation.value.push(...val)
  }
  watch(fieldValidation, () => {
    _validate()
  })

  const _validate = () => {
    if (fieldValidation.value.length > 0) {
      valid.value = fieldValidation.value.reduce((acc, key) => {
        if (acc && !form[key].valid) return false
        return acc
      }, true)
      return
    }
    valid.value = Object.keys(init).reduce((acc, key) => {
      if (acc && !form[key].valid) return false
      return acc
    }, true)
    emitter.emit('form-update')
  }

  const validate = () => {
    Object.keys(init).forEach((key) => {
      if (form[key].touched) form[key].validate()
      else form[key].touched = true
    })
    _validate()
  }

  const clean = () => {
    Object.keys(init).forEach((key) => (form[key].value = ''))
  }

  const toJson = () => {
    const obj = {}
    Object.keys(init).forEach((key) => {
      obj[key] = form[key].toJson()
    })
    obj.valid = valid.value
    return obj
  }

  const allTouched = () => {
    if (fieldValidation.value.length > 0) {
      return fieldValidation.value.forEach((key) => {
        form[key].touched = true
      })
    }
    Object.keys(init).forEach((key) => {
      form[key].touched = true
    })
  }

  for (const [key, value] of Object.entries(init)) {
    const field = useFormField(value)
    form[key] = field
    field.emitter.on('validated', () => {
      // Вызываем валидацию формы
      _validate()
      // Вызываем событие об изменении формы
      emitter.emit('form-update')
    })
    if (value.repeat) {
      form[value.repeat]?.emitter.on('validated', () => field.validate())
      field.emitter.on('validated', (val) => {
        if (form[value.repeat]?.value !== val) {
          field.errors.push('Поле не совпадает')
          field.valid.value = false
        } else if (field.errors.length === 0) {
          field.valid.value = true
        }
      })
    }
  }
  _validate()

  return {
    ...form,
    clean,
    valid,
    validate,
    toJson,
    emitter,
    updateFieldValidation,
    fieldValidation,
    allTouched,
  }
}
