import { computed, inject, ref } from 'vue'
import { useStore } from 'vuex'
import axios from '@/plugins/axios'

/**
 * @typedef PromocodeRequestConfig
 * @type Object
 * @property id {Number}
 * @property body {Object} Объект тела запроса
 * @property body.name {String} Название промокода
 * @property body.active_to {String} Срок действия промокода
 * @property body.discount_percent {String} Скидка в процентах
 * @property body.code {String} Строка промокода
 * @property body.max_usage_for_one_user {String} Количество использований для одного пользователя*
 * @property body.max_common_usages {String} Количество использований промокода*
 * @property successCallback {Function} Коллбек успешного выполнения запроса
 * @property errorCallback {Function} Колбек ошибки выполнения запроса
 */

export default function newPromocodeController(initId = undefined, config) {
  const id = ref(initId)
  const store = config?.store ?? useStore()
  const notification = config?.notification ?? inject('notification')

  const methods = {
    index: (config) => {
      axios({
        url: `/promos?page=${config.page}&${config.searchParams}`,
        method: 'GET',
      })
        .then((res) => {
          store.commit('invoice/updatePromocodeList', res.data)
          config.successCallback(res.data)
        })
        .catch((error) => {
          notification.errorMessageBox({
            message: 'Ошибка при получении списка промокодов',
          })
          notification.rollbar(
            'error',
            'Ошибка при получении списка промокодов',
            JSON.stringify({
              error,
              errorInfo: error?.cause,
            })
          )
        })
    },
    /**
     *
     * @param config {PromocodeRequestConfig}
     */
    create: (config) => {
      axios({
        url: '/promos',
        method: 'POST',
        data: config.body,
      })
        .then((res) => {
          store.commit('invoice/updatePromocode', res.data)
          config.successCallback?.(res.data)
        })
        .catch((error) => {
          notification.errorMessageBox({
            message: 'Ошибка при создании промокода',
          })
          notification.rollbar(
            'error',
            'Ошибка при создании промокода',
            JSON.stringify({
              error,
              errorInfo: error?.cause,
            })
          )
        })
    },
    /**
     * @param config
     * @param config.id {Number}
     */
    show: (config) => {
      axios({
        url: `/promos/${config.id || id.value}`,
        method: 'GET',
      })
        .then((res) => {
          store.commit('invoice/updatePromocode', res.data)
          config.successCallback?.(res.data)
        })
        .catch((error) => {
          notification.errorMessageBox({
            message: 'Ошибка при получении информации по промокоду',
          })
          notification.rollbar(
            'error',
            'Ошибка при получении информации по промокоду',
            JSON.stringify({
              error,
              errorInfo: error?.cause,
            })
          )
        })
    },
    /**
     * @param config {PromocodeRequestConfig}
     */
    update: (config) => {
      axios({
        url: `/promos/${config.id}`,
        method: 'PATCH',
        data: config.body,
      })
        .then((res) => {
          store.commit('invoice/updatePromocode', res.data)
          config.successCallback?.(res.data)
        })
        .catch((error) => {
          notification.errorMessageBox({
            message: 'Ошибка при обновлении промокода',
          })
          notification.rollbar(
            'error',
            'Ошибка при обновлении промокода',
            JSON.stringify({
              error,
              errorInfo: error?.cause,
            })
          )
        })
    },
    destroy: (config) => {
      axios({
        url: `/promos/${config.id}`,
        method: 'DELETE',
      })
        .then((res) => {
          config.successCallback?.(res.data)
        })
        .catch((error) => {
          notification.errorMessageBox({
            message: 'Ошибка при удалении промокода',
          })
          notification.rollbar(
            'error',
            'Ошибка при удалении промокода',
            JSON.stringify({
              error,
              errorInfo: error?.cause,
            })
          )
        })
    },
  }
  const getters = {
    show: computed(() => store.state.invoice.activePromocode),
    index: computed(() => store.state.invoice.promocodeList),
  }
  return {
    id,
    methods,
    getters,
  }
}
