export default function indexMiddleware({ from, next, store }) {
  if (store.getters['user/is_login']) {
    next({
      path: '/account',
    })
  } else {
    next({
      path: '/authorization'
    })
  }
}
