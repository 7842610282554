export default {
  state: {
    settingsOpen: false,
  },
  /**
   * @return {boolean}
   */
  getters: {
    getSettingsState: (state) => state.settingsOpen,
  },
  /**
   * @return {toggle}
   */
  mutations: {
    toggleSettings: (state) => (state.settingsOpen = !state.settingsOpen),
    setSettingsClose: (state) => (state.settingsOpen = false),
  },
}
