import newInvoiceController from '@/controllers/InvoiceController'
import usePolitic from '@/use/usePolitic'
import newAdminInvoiceController from '@/controllers/Admin/AdminInvoiceController'
import useInvoiceRequest from '@/use/useInvoiceRequest'

export default function ReloadInvoiceMiddleware({ notification, store, next, to }) {
  store.commit('service/toggleRedirectLoader', true)
  const politics = usePolitic(store)
  const controller = politics.isAdmin.value ? newAdminInvoiceController : newInvoiceController
  const InvoiceController = controller({
    store,
    notification,
  })

  InvoiceController.methods.show({
    id: to.params.id,
    successCallback: (res) => {
      useInvoiceRequest(res, store, next)
    },
    errorCallback: () => {
      store.commit('service/toggleRedirectLoader', false)
      next({
        name: 'account home',
      })
    },
  })
}
