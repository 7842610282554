export default function useScroll() {
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
  const isScrolling = (method) => {
    window.addEventListener('scroll', method, { passive: false })
  }
  return {
    scrollTop,
    isScrolling,
  }
}
