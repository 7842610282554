import axios from '@/plugins/axios'

export default function CheckExanteTokenInAccount({ from, next, store }) {
  if (store.getters['user/hasExanteRegToken']) {
    axios({
      url: '/exante/users/assign_invoice',
      method: 'POST',
      data: {
        token: store.getters['user/getExanteRegToken'],
      },
    })
      .then((res) => {
        const id = res.data?.invoice?.id
        console.log(res, id)
        if (id) {
          store.commit('user/updateExanteRegToken', null)
          next({
            name: 'invoice show',
            params: {
              id: id,
            },
          })
        } else {
          next()
        }
      })
      .catch((err) => {
        console.error('check err', err)
        if (from.name === 'exanteRegistration') {
          store.commit('user/updateExanteError', err.message)
          next({ ...from })
        } else {
          next()
        }
      })
  } else {
    next()
  }
}
