<template>
  <div class="margin-v-s">
    <p v-if="label" class="label font-weight--extra margin-v-xs">
      {{ label }}
    </p>
    <div class="form-slot">
      <slot />
      <p v-if="model" :class="{ 'rb-form-error-hide': model?.valid || !model?.touched }" class="font-color--red">
        {{ model?.errors[0] || 'нет ошибок' }}
      </p>
    </div>
  </div>
</template>

<script>
import useWindowWidth from '@/use/useWindowWidth'

export default {
  name: 'NgGlobalCheckboxItem',
  props: {
    label: String,
    model: Object,
  },
  setup() {
    const { screenIsMobile } = useWindowWidth()

    return {
      screenIsMobile,
    }
  },
}
</script>

<style scoped lang="sass">
.label
  text-align: right
  vertical-align: middle
  float: left
  box-sizing: border-box
.rb-form-error
  line-height: 14px
  opacity: 1
  margin-bottom: 10px
  &-hide
    opacity: 0 !important
.form-slot
  position: relative
.form-warning
  position: absolute
  right: -5px
  top: -5px
</style>
