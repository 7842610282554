/*eslint-disable*/
import { useStore } from 'vuex'
import { computed, ref, watchEffect } from 'vue'
import { useRouter } from 'vue-router'

export default function () {
  const store = useStore()
  const widgetRef = ref()
  const router = useRouter()

  const user = computed(() => store.getters['user/get_user'])
  const methods = {
    hideContactForm: () => {
      window.addEventListener('onBitrixLiveChat', (event) => {
        const widget = event.detail.widget
        widgetRef.value = widget
        watchEffect(() => {
          if (Object.keys(router.currentRoute.value.query).includes('need-o-c')) {
            widget.open()
          }
          if (store.getters['user/is_login']) {
            widget.setUserRegisterData({
              hash: 'hash',
              name: user.value.email,
              lastName: 'Пользователь ЛК',
              email: user.value.email,
              gender: 'transparent',
              avatar: 'inherit',
              position: 'B24',
            })
            widget.mutateTemplateComponent('bx-livechat-form-welcome', {
              template: '<div></div>',
            })
          }
        })
      })
    },
  }
  return {
    methods,
  }
}
