export default function useInvoiceAsyncCallback(res, config, methodConfig) {
  methodConfig.update(res)
  return new Promise((resolve) => {
    if (methodConfig.pathAsync.reduce((acc, item) => acc?.[item], res.data)) {
      methodConfig.updateTimer(
        setTimeout(() => {
          methodConfig.method(config)
        }, 3000),
        res
      )
    } else {
      methodConfig.clearTimer(res)
      resolve(res)
    }
  })
}
